/*
 * Enterprise Life Gambia offline processes
 */

const PRODUCTS = [
  {
    id: 'educare-plan-enhanced',
    label: 'Educare Plus',
    description:
      "The policy is designed to provide benefit payments, which are intended to cover the education expenses of the policyholder's child. The product is a term policy with both life cover and investment component.",
  },
  {
    id: 'lifetime-needs-plus',
    label: 'Lifetime Needs Plus Plan',
    description:
      "This product is designed to provide the member with the means of contributing to an investment fund which can meet the member's lifetime needs. These needs include child education, retirement (old age expenses), vacation, engagement and wedding as well as housing project.",
  },
]

PRODUCTS.forEach((product) => {
  module.exports[product.id] = Object.assign(
    {},
    product,
    require(`./products/${product.id}`).default
  )
})
