/*
 *  ___                  __                __  _ _
 *   | |_  _     _ |_ |_|_    _  _ _ _ _  /  \(_(_|. _  _
 *   | | )(_)|_|(_)| )|_|__)(|_)| (-_)_)  \__/| | ||| )(-
 *              _/           |
 */

import { updateItemIn } from '../../../../src/core/dataitems'
import {
  hasValue,
  validEmail,
  verify,
  validAmountRange,
} from '../../../../src/core/validations'
import { validName, validCellnumber, onlyDigits } from '../../validations'
import dayjs from 'dayjs'
const now = dayjs()

export const validate = (state, requiredOnly, validateOnly) => {
  if (process.env.DISABLE_VALIDATIONS === 'true') {
    return true
  }

  let validates = true
  switch (state.step) {
    case 'quotation-screen':
      // Validate age
      validates =
        verify(
          state,
          state.form['life-assured-identification']['date-of-birth'],
          (v) =>
            hasValue(v) &&
            (requiredOnly ||
              (dayjs().diff(v, 'year') >= 18 && dayjs().diff(v, 'year') <= 59)),
          !validateOnly && { id: 'date-of-birth' },
          requiredOnly
            ? ['*']
            : ['The age is outside the allowable bounds for this product.']
        ) && validates

      // Term in years
      validates =
        verify(
          state,
          state.form['selected-premium']['term-in-years'],
          hasValue,
          !validateOnly && { id: 'term-in-years' },
          ['*']
        ) && validates

      if (validates && !requiredOnly) {
        validates =
          verify(
            state,
            state.form['selected-premium']['term-in-years'],
            (v) =>
              v <=
              70 -
                dayjs().diff(
                  state.form['life-assured-identification']['date-of-birth'],
                  'year'
                ),
            !validateOnly && { id: 'term-in-years' },
            ['The policy cannot be active after your 70th birth-date']
          ) && validates
      }

      // Validate selected premium required fields
      validates =
        verify(
          state,
          state.form['selected-premium']['educare-premium'],
          (v) => (requiredOnly ? hasValue(v) : validAmountRange(v, 300, 2500)),
          !validateOnly && { id: 'educare-premium' },
          requiredOnly
            ? ['*']
            : ['Investment premium can only be between D 300.00 and D 2500.00']
        ) && validates

      // Update Option
      validates =
        verify(
          state,
          state.form['selected-premium']['update-option'],
          hasValue,
          !validateOnly && { id: 'update-option' },
          ['*']
        ) && validates
      break

    case 'personal-details':
      // Life assured details
      validates = Object.keys(state.form['life-assured-identification'])
        .filter((k) => ['idpassport-number', 'marital-status'].indexOf(k) < 0)
        .reduce(
          (valid, field) =>
            verify(
              state,
              state.form['life-assured-identification'][field],
              hasValue,
              !validateOnly && { id: field },
              ['*']
            ) && valid,
          validates
        )

      //Validate field name without numbers
      validates =
        verify(
          state,
          state.form['life-assured-identification']['first-names'],
          (v) => (requiredOnly ? hasValue(v) : validName(v)),
          !validateOnly && { id: 'first-names' },
          requiredOnly ? ['*'] : ['Please enter a valid first-name']
        ) && validates

      validates =
        verify(
          state,
          state.form['life-assured-identification']['surname'],
          (v) => (requiredOnly ? hasValue(v) : validName(v)),
          !validateOnly && { id: 'surname' },
          requiredOnly ? ['*'] : ['Please enter a valid surname']
        ) && validates

      // Occupation
      validates =
        verify(
          state,
          state.form['life-assured-employment-details']['occupation'],
          hasValue,
          !validateOnly && { id: 'occupation' },
          ['*']
        ) && validates

      // Postal Address
      validates =
        verify(
          state,
          state.form['life-assured-postal-address']['town'],
          hasValue,
          !validateOnly && { id: 'town' },
          ['*']
        ) && validates

      // Postal Address
      validates =
        verify(
          state,
          state.form['life-assured-postal-address']['region'],
          hasValue,
          !validateOnly && { id: 'region' },
          ['*']
        ) && validates

      // Cell phone number
      validates =
        verify(
          state,
          state.form['life-assured-contact-details']['cell-phone'],
          (v) => (requiredOnly ? hasValue(v) : validCellnumber(v)),
          !validateOnly && { id: 'cell-phone' },
          requiredOnly ? ['*'] : ['Please enter a valid Cellphone Number']
        ) && validates

      // Email address
      validates =
        verify(
          state,
          state.form['life-assured-contact-details'].email,
          (v) => (requiredOnly ? hasValue(v) : validEmail(v)),
          !validateOnly && { id: 'email' },
          requiredOnly ? ['*'] : ['Please enter a valid Email address']
        ) && validates

      //Validate the gender & title
      if (state.form['life-assured-identification']['gender'] === 'Female') {
        validates =
          verify(
            state,
            state.form['life-assured-identification']['title'],
            (v) =>
              hasValue(v) &&
              (requiredOnly ||
                ['Miss', 'Ms', 'Mrs', 'Adv', 'Dr', 'Prof', 'Rev'].indexOf(
                  state.form['life-assured-identification']['title']
                ) >= 0),
            !validateOnly && { id: 'title' },
            requiredOnly
              ? ['*']
              : ['The selected title and gender does not correspond.']
          ) && validates
      }

      if (state.form['life-assured-identification']['gender'] === 'Male') {
        validates =
          verify(
            state,
            state.form['life-assured-identification']['title'],
            (v) =>
              hasValue(v) &&
              (requiredOnly ||
                ['Mr', 'Adv', 'Dr', 'Prof', 'Rev'].indexOf(
                  state.form['life-assured-identification']['title']
                ) >= 0),
            !validateOnly && { id: 'title' },
            requiredOnly
              ? ['*']
              : ['The selected title and gender does not correspond.']
          ) && validates
      }
      break

    case 'spouse-details':
      // Spouse details
      validates = Object.keys(state.form['spouse-identification'])
        .filter((k) => ['idpassport-number'].indexOf(k) < 0)
        .reduce(
          (valid, field) =>
            verify(
              state,
              state.form['spouse-identification'][field],
              hasValue,
              !validateOnly && { id: field },
              ['*']
            ) && valid,
          validates
        )

      //Validate field names without numbers
      validates =
        verify(
          state,
          state.form['spouse-identification']['first-names'],
          (v) => (requiredOnly ? hasValue(v) : validName(v)),
          !validateOnly && { id: 'first-names' },
          requiredOnly ? ['*'] : ['Please enter a valid first-name']
        ) && validates

      validates =
        verify(
          state,
          state.form['spouse-identification']['surname'],
          (v) => (requiredOnly ? hasValue(v) : validName(v)),
          !validateOnly && { id: 'surname' },
          requiredOnly ? ['*'] : ['Please enter a valid surname']
        ) && validates

      // Validate age
      validates =
        verify(
          state,
          state.form['spouse-identification']['date-of-birth'],
          (v) =>
            hasValue(v) &&
            (requiredOnly ||
              (dayjs().diff(v, 'year') >= 18 && dayjs().diff(v, 'year') <= 60)),
          !validateOnly && { id: 'date-of-birth' },
          requiredOnly
            ? ['*']
            : ['The age is outside the allowable bounds for this product.']
        ) && validates

      //Validate the gender & title
      if (state.form['spouse-identification']['gender'] === 'Female') {
        validates =
          verify(
            state,
            state.form['spouse-identification']['title'],
            (v) =>
              hasValue(v) &&
              (requiredOnly ||
                ['Miss', 'Ms', 'Mrs', 'Adv', 'Dr', 'Prof', 'Rev'].indexOf(
                  state.form['spouse-identification']['title']
                ) >= 0),
            !validateOnly && { id: 'title' },
            requiredOnly
              ? ['*']
              : ['The selected title and gender does not correspond.']
          ) && validates
      }

      if (state.form['spouse-identification']['gender'] === 'Male') {
        validates =
          verify(
            state,
            state.form['spouse-identification']['title'],
            (v) =>
              hasValue(v) &&
              (requiredOnly ||
                ['Mr', 'Adv', 'Dr', 'Prof', 'Rev'].indexOf(
                  state.form['spouse-identification']['title']
                ) >= 0),
            !validateOnly && { id: 'title' },
            requiredOnly
              ? ['*']
              : ['The selected title and gender does not correspond.']
          ) && validates
      }
      break

    case 'trustee-details':
      // Trustee details
      validates = Object.keys(state.form['trustee-identification'])
        .filter((k) => ['idpassport-number', 'marital-status'].indexOf(k) < 0)
        .reduce(
          (valid, field) =>
            verify(
              state,
              state.form['trustee-identification'][field],
              hasValue,
              !validateOnly && { id: field },
              ['*']
            ) && valid,
          validates
        )

      //Validate field names without numbers
      validates =
        verify(
          state,
          state.form['trustee-identification']['first-names'],
          (v) => (requiredOnly ? hasValue(v) : validName(v)),
          !validateOnly && { id: 'first-names' },
          requiredOnly ? ['*'] : ['Please enter a valid first-name']
        ) && validates

      validates =
        verify(
          state,
          state.form['trustee-identification']['surname'],
          (v) => (requiredOnly ? hasValue(v) : validName(v)),
          !validateOnly && { id: 'surname' },
          requiredOnly ? ['*'] : ['Please enter a valid surname']
        ) && validates

      // Validate age
      validates =
        verify(
          state,
          state.form['trustee-identification']['date-of-birth'],
          (v) => hasValue(v) && (requiredOnly || dayjs().diff(v, 'year') >= 18),
          !validateOnly && { id: 'date-of-birth' },
          requiredOnly
            ? ['*']
            : ['The minimum allowable age for this product is 18.']
        ) && validates

      //Validate the gender & title
      if (state.form['trustee-identification'].gender === 'Female') {
        validates =
          verify(
            state,
            state.form['trustee-identification'].title,
            (v) =>
              hasValue(v) &&
              (requiredOnly ||
                ['Miss', 'Ms', 'Mrs', 'Adv', 'Dr', 'Prof', 'Rev'].indexOf(
                  state.form['trustee-identification'].title
                ) >= 0),
            !validateOnly && { id: 'title' },
            requiredOnly
              ? ['*']
              : ['The selected title and gender does not correspond.']
          ) && validates
      }

      if (state.form['trustee-identification'].gender === 'Male') {
        validates =
          verify(
            state,
            state.form['trustee-identification'].title,
            (v) =>
              hasValue(v) &&
              (requiredOnly ||
                ['Mr', 'Adv', 'Dr', 'Prof', 'Rev'].indexOf(
                  state.form['trustee-identification'].title
                ) >= 0),
            !validateOnly && { id: 'title' },
            requiredOnly
              ? ['*']
              : ['The selected title and gender does not correspond.']
          ) && validates
      }

      //Validate the gender & relationship
      if (state.form['trustee-identification']['gender'] === 'Female') {
        validates =
          verify(
            state,
            state.form['trustee-identification']['relationship'],
            (v) =>
              hasValue(v) &&
              (requiredOnly ||
                [
                  'Daughter',
                  'Estate',
                  'Grandmother',
                  'Mother',
                  'Mother-In-Law',
                  'Sister',
                  'Spouse',
                  'Testament',
                  'Trust',
                ].indexOf(
                  state.form['trustee-identification']['relationship']
                ) >= 0),
            !validateOnly && { id: 'relationship' },
            requiredOnly
              ? ['*']
              : ['The selected relationship and gender does not correspond.']
          ) && validates
      }

      if (state.form['trustee-identification']['gender'] === 'Male') {
        validates =
          verify(
            state,
            state.form['trustee-identification']['relationship'],
            (v) =>
              hasValue(v) &&
              (requiredOnly ||
                [
                  'Brother',
                  'Estate',
                  'Father',
                  'Son',
                  'Father-In-Law',
                  'Grandfather',
                  'Spouse',
                  'Testament',
                  'Trust',
                ].indexOf(
                  state.form['trustee-identification']['relationship']
                ) >= 0),
            !validateOnly && { id: 'relationship' },
            requiredOnly
              ? ['*']
              : ['The selected relationship and gender does not correspond.']
          ) && validates
      }

      // Cell phone number
      validates =
        verify(
          state,
          state.form['trustee-contact-details']['cell-phone'],
          (v) => (requiredOnly ? hasValue(v) : validCellnumber(v)),
          !validateOnly && { id: 'cell-phone' },
          requiredOnly ? ['*'] : ['Please enter a valid Cellphone Number']
        ) && validates
      break

    case 'beneficiary-details':
      if (!requiredOnly) {
        validates =
          verify(
            state,
            state.form['beneficiary-details'],
            (v) => v.length > 0,
            !validateOnly && { id: 'specify-beneficiaries', type: 'list' },
            ['Please add a beneficiary']
          ) && validates
      }
      break

    case 'add-beneficiary':
    case 'edit-beneficiary':
      validates = [
        'first-names',
        'surname',
        'date-of-birth',
        'role',
        'gender',
      ].reduce(
        (valid, field) =>
          verify(
            state,
            state.local[field],
            hasValue,
            !validateOnly && { id: field },
            ['*']
          ) && valid,
        validates
      )

      //Validate the field names to remove letters
      validates =
        verify(
          state,
          state.local['first-names'],
          (v) => (requiredOnly ? hasValue(v) : validName(v)),
          !validateOnly && { id: 'first-names' },
          requiredOnly ? ['*'] : ['Please enter a valid first-name']
        ) && validates

      validates =
        verify(
          state,
          state.local['surname'],
          (v) => (requiredOnly ? hasValue(v) : validName(v)),
          !validateOnly && { id: 'surname' },
          requiredOnly ? ['*'] : ['Please enter a valid surname']
        ) && validates

      //Validate the gender & role
      if (state.local['gender'] === 'Female') {
        validates =
          verify(
            state,
            state.local['role'],
            (v) =>
              hasValue(v) &&
              (requiredOnly ||
                [
                  'Child',
                  'Cousin',
                  'Daughter',
                  'Family',
                  'Grandchild',
                  'Grandmother',
                  'Main',
                  'Mother',
                  'Mother-in-law',
                  'Niece',
                  'Sister',
                  'Spouse',
                  'Step-Mother',
                ].indexOf(state.local['role']) >= 0),
            !validateOnly && { id: 'role' },
            requiredOnly
              ? ['*']
              : ['The selected role and gender does not correspond.']
          ) && validates
      }

      if (state.local['gender'] === 'Male') {
        validates =
          verify(
            state,
            state.local['role'],
            (v) =>
              hasValue(v) &&
              (requiredOnly ||
                [
                  'Brother',
                  'Child',
                  'Cousin',
                  'Family',
                  'Father',
                  'Father-in-law',
                  'Grandchild',
                  'Grandfather',
                  'Main',
                  'Nephew',
                  'Son',
                  'Spouse',
                  'Step-Father',
                ].indexOf(state.local['role']) >= 0),
            !validateOnly && { id: 'role' },
            requiredOnly
              ? ['*']
              : ['The selected role and gender does not correspond.']
          ) && validates
      }

      //Validate the DOB to not be selected in the future
      validates =
        verify(
          state,
          state.local['date-of-birth'],
          (v) => hasValue(v) && (requiredOnly || dayjs().isAfter(v, now)),
          !validateOnly && { id: 'date-of-birth' },
          requiredOnly
            ? ['*']
            : ['Please ensure the date of birth is not selected in the future.']
        ) && validates
      break

    case 'update-beneficiary-split-percentage':
      if (!requiredOnly) {
        validates =
          Math.ceil(
            Object.keys(state.local || {})
              .filter((k) => k.startsWith('beneficiary-split'))
              .reduce((t, b) => {
                const v = parseFloat(state.local[b])
                return isNaN(v) ? -1.0 : v + t
              }, 0.0)
          ) === 100 && validates
        if (!validateOnly) {
          updateItemIn(
            state.page.item,
            { id: 'update-benefit-split-percentage' },
            {
              errors: validates ? [] : ['The allocation must add up to 100.00'],
            }
          )
        }
      }
      break

    case 'medical-underwriting':
      validates = Object.keys(state.form['life-assured-health-details'])
        .filter(
          (k) =>
            [
              'medical-advice-stop-smoking',
              'smoker',
              'non-smoker',
              'never-smoked',
              'years-smoking',
              'years-not-smoking',
              'cigarettes-per-day',
              'cigars-per-day',
              'pipe',
            ].indexOf(k) < 0
        )
        .reduce(
          (valid, field) =>
            verify(
              state,
              state.form['life-assured-health-details'][field],
              hasValue,
              !validateOnly && { id: field },
              ['*']
            ) && valid,
          validates
        )

      validates =
        verify(
          state,
          state.form['life-assured-health-details']['smoker'] === 'Y' ||
            state.form['life-assured-health-details']['non-smoker'] === 'Y' ||
            state.form['life-assured-health-details']['never-smoked'] === 'Y',
          hasValue,
          !validateOnly && { id: 'smoker-options-radio-group' },
          ['*']
        ) && validates

      let age = dayjs().diff(
        state.form['life-assured-identification']['date-of-birth'],
        'year'
      )

      if (state.form['life-assured-health-details'].smoker === 'Y') {
        let yrs = parseInt(
          state.form['life-assured-health-details']['years-smoking']
        )

        validates =
          verify(
            state,
            state.form['life-assured-health-details']['years-smoking'],
            (v) =>
              requiredOnly
                ? hasValue(v)
                : hasValue(v) && !isNaN(yrs) && yrs > 0 && yrs <= age,
            !validateOnly && { id: 'years-smoking' },
            requiredOnly ? ['*'] : ['Please enter a valid amount of years']
          ) && validates

        validates = ['cigarettes-per-day', 'cigars-per-day', 'pipe'].reduce(
          (valid, field) =>
            verify(
              state,
              state.form['life-assured-health-details'][field],
              hasValue,
              !validateOnly && { id: field },
              ['*']
            ) && valid,
          validates
        )
      }
      if (state.form['life-assured-health-details']['non-smoker'] === 'Y') {
        let yrs = parseInt(
          state.form['life-assured-health-details']['years-not-smoking']
        )
        validates =
          verify(
            state,
            state.form['life-assured-health-details']['years-not-smoking'],
            (v) =>
              requiredOnly
                ? hasValue(v)
                : hasValue(v) && !isNaN(yrs) && yrs > 0 && yrs <= age,
            !validateOnly && { id: 'years-not-smoking' },
            requiredOnly ? ['*'] : ['Please enter a valid amount of years']
          ) && validates
      }
      break

    case 'payment-details':
      validates =
        verify(
          state,
          state.form['payment-method-selection']['debit-order'] ||
            state.form['payment-method-selection']['stop-order'],
          hasValue,
          !validateOnly && { id: 'payment-method-radio-group' },
          ['*']
        ) && validates

      validates =
        verify(
          state,
          state.form['payment-start-date'],
          hasValue,
          !validateOnly && { id: 'payment-start-date' },
          ['*']
        ) && validates

      validates =
        verify(
          state,
          state.form['payment-frequency'],
          hasValue,
          !validateOnly && { id: 'payment-frequency' },
          ['*']
        ) && validates

      validates =
        verify(
          state,
          state.form['payer-person']['myself'] ||
            state.form['payer-person']['my-spouse'],
          hasValue,
          !validateOnly && { id: 'payer-person-radio-group' },
          ['*']
        ) && validates
      break

    case 'debit-order-details':
      validates = Object.keys(state.form['payment-bank-details']).reduce(
        (valid, field) =>
          verify(
            state,
            state.form['payment-bank-details'][field],
            hasValue,
            !validateOnly && { id: field },
            ['*']
          ) && valid,
        validates
      )

      validates =
        verify(
          state,
          state.form['payment-bank-details']['account-holder-name'],
          (v) => (requiredOnly ? hasValue(v) : validName(v)),
          !validateOnly && { id: 'account-holder-name' },
          requiredOnly ? ['*'] : ['This field does not accept digits']
        ) && validates

      //VALIDATE ACCOUNT NUMBER LENGTH BASED ON THE ACCOUNT ENTITY NAME CHOSEN
      if (!requiredOnly) {
        //10 DIGITS //11 DIGITS //12 DIGITS //13DIGITS// 14 DIGITS// 15 DIGITS// 16 DIGITS
        if (
          (state.form['payment-bank-details']['account-entity-name'] ===
            'Bsic Gambia' ||
            state.form['payment-bank-details']['account-entity-name'] ===
              'Ecobank Gambia') &&
          state.form['payment-bank-details']['account-number'] !== null
        ) {
          validates =
            verify(
              state,
              state.form['payment-bank-details']['account-number'],
              (v) =>
                (v.length === 10 ||
                  v.length === 11 ||
                  v.length === 12 ||
                  v.length === 13 ||
                  v.length === 14 ||
                  v.length === 15 ||
                  v.length === 16) &&
                onlyDigits(v),
              !validateOnly && { id: 'account-number' },
              ['Please enter a valid account number']
            ) && validates
        }
        //12 / 13 / 14 / 15 DIGITS
        else if (
          (state.form['payment-bank-details']['account-entity-name'] ===
            'Vista bank' ||
            state.form['payment-bank-details']['account-entity-name'] ===
              'Megabank Gambia Limited') &&
          state.form['payment-bank-details']['account-number'] !== null
        ) {
          validates =
            verify(
              state,
              state.form['payment-bank-details']['account-number'],
              (v) =>
                (v.length === 12 ||
                  v.length === 13 ||
                  v.length === 14 ||
                  v.length === 15) &&
                onlyDigits(v),
              !validateOnly && { id: 'account-number' },
              ['Please enter a valid account number']
            ) && validates
        }
        //13 DIGITS
        else if (
          (state.form['payment-bank-details']['account-entity-name'] ===
            'Access Bank Gambia' ||
            state.form['payment-bank-details']['account-entity-name'] ===
              'Standard Chartered Bank' ||
            state.form['payment-bank-details']['account-entity-name'] ===
              'Bloom Bank Africa') &&
          state.form['payment-bank-details']['account-number'] !== null
        ) {
          validates =
            verify(
              state,
              state.form['payment-bank-details']['account-number'],
              (v) => v.length === 13 && onlyDigits(v),
              !validateOnly && { id: 'account-number' },
              ['Please enter a valid account number']
            ) && validates
        }
        //11 DIGITS
        else if (
          (state.form['payment-bank-details']['account-entity-name'] ===
            'Trust Bank Gambia' ||
            state.form['payment-bank-details']['account-entity-name'] ===
              'Supersonicz') &&
          state.form['payment-bank-details']['account-number'] !== null
        ) {
          validates =
            verify(
              state,
              state.form['payment-bank-details']['account-number'],
              (v) => v.length === 11 && onlyDigits(v),
              !validateOnly && { id: 'account-number' },
              ['Please enter a valid account number']
            ) && validates
        }
        //15 DIGITS
        else if (
          state.form['payment-bank-details']['account-entity-name'] ===
            'Fbn bank' &&
          state.form['payment-bank-details']['account-number'] !== null
        ) {
          validates =
            verify(
              state,
              state.form['payment-bank-details']['account-number'],
              (v) => v.length === 15 && onlyDigits(v),
              !validateOnly && { id: 'account-number' },
              ['Please enter a valid account number']
            ) && validates
        }
        //18 DIGITS
        else if (
          state.form['payment-bank-details']['account-entity-name'] ===
            'Arab Gambia Islamic Bank' &&
          state.form['payment-bank-details']['account-number'] !== null
        ) {
          validates =
            verify(
              state,
              state.form['payment-bank-details']['account-number'],
              (v) => v.length === 18 && onlyDigits(v),
              !validateOnly && { id: 'account-number' },
              ['Please enter a valid account number']
            ) && validates
        }
        //10 DIGITS
        else if (
          state.form['payment-bank-details']['account-entity-name'] ===
            'Zenith Bank Gambia' &&
          state.form['payment-bank-details']['account-number'] !== null
        ) {
          validates =
            verify(
              state,
              state.form['payment-bank-details']['account-number'],
              (v) => v.length === 10 && onlyDigits(v),
              !validateOnly && { id: 'account-number' },
              ['Please enter a valid account number']
            ) && validates
        }
        //13 /14 /15 /16 /17 DIGITS
        else if (
          state.form['payment-bank-details']['account-entity-name'] ===
            'Reliance Gambia' &&
          state.form['payment-bank-details']['account-number'] !== null
        ) {
          validates =
            verify(
              state,
              state.form['payment-bank-details']['account-number'],
              (v) =>
                (v.length === 13 ||
                  v.length === 14 ||
                  v.length === 15 ||
                  v.length === 16 ||
                  v.length === 17) &&
                onlyDigits(v),
              !validateOnly && { id: 'account-number' },
              ['Please enter a valid account number']
            ) && validates
        }
        //10 /11 /12 /13 DIGITS
        else if (
          state.form['payment-bank-details']['account-entity-name'] ===
            'Gt Bank Gambia' &&
          state.form['payment-bank-details']['account-number'] !== null
        ) {
          validates =
            verify(
              state,
              state.form['payment-bank-details']['account-number'],
              (v) =>
                (v.length === 10 ||
                  v.length === 11 ||
                  v.length === 12 ||
                  v.length === 13) &&
                onlyDigits(v),
              !validateOnly && { id: 'account-number' },
              ['Please enter a valid account number']
            ) && validates
        }
      }

      validates =
        verify(
          state,
          state.form['payer-signature'],
          hasValue,
          !validateOnly && { id: 'interactive-canvas-html' },
          requiredOnly ? ['*'] : ['Your signature is required']
        ) && validates
      break

    case 'stop-order-details':
      validates = Object.keys(state.form['payment-employment-details']).reduce(
        (valid, field) =>
          verify(
            state,
            state.form['payment-employment-details'][field],
            hasValue,
            !validateOnly && { id: field },
            ['*']
          ) && valid,
        validates
      )
      validates =
        verify(
          state,
          state.form['payer-signature'],
          hasValue,
          !validateOnly && { id: 'interactive-canvas-html' },
          requiredOnly ? ['*'] : ['Your signature is required']
        ) && validates
      break
  }
  if (!requiredOnly && !validates) {
    state.local = state.local || {}
    state.local.failed_validation = true
  }
  return validates
}
