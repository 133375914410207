/*
 *  ___                  __                __  _ _
 *   | |_  _     _ |_ |_|_    _  _ _ _ _  /  \(_(_|. _  _
 *   | | )(_)|_|(_)| )|_|__)(|_)| (-_)_)  \__/| | ||| )(-
 *              _/           |
 */

export const pages = {
  'confirm-cancel': {
    modal: true,
    text: [],
    buttons: [
      { label: 'Yes', id: 'yes', uid: '0baa62eba1b1a7d7', control: true },
      { label: 'No', id: 'no', uid: '6c0e0e2956ec3933', control: true },
    ],
    item: {
      id: 'message',
      type: 'text',
      readonly: true,
      label: 'Message',
      uid: '9bc15702a2e49228',
      content: ['Are you sure you want to cancel this application?'],
    },
    title: 'Public Access \n Welcome to Elac Gambia',
  },
  'quotation-screen': {
    text: [],
    buttons: [
      {
        label: 'Next',
        id: 'next',
        uid: '26ca340069ec84e5',
        control: true,
      },
      {
        label: 'Cancel',
        id: 'cancel',
        uid: 'e25c3188a733209e',
        control: true,
      },
    ],
    item: {
      id: 'quote-screen',
      type: 'list',
      readonly: false,
      label: 'Quote Screen',
      uid: '0cc7c183689692a2',
      content: [
        {
          id: 'system-constant',
          type: 'html',
          readonly: true,
          label: '',
          uid: '552d19e7a2f676a3',
          content: '<h2>Choose your cover</h2>\n',
        },
        {
          id: 'system-constant',
          type: 'html',
          readonly: true,
          label: '',
          uid: 'a1fcd090d9138ba4',
          content:
            '<p>To be able to provide you with a quick quotation we will need the following information from you.</p>\n',
        },
        {
          id: 'output-html',
          type: 'html',
          readonly: true,
          label: 'Output',
          uid: '56dfc210727e82c4',
          content:
            "<ol class='progress-indicator'>\n<li class='done clickable'><a href='#cover'>Cover</a></li>\n<li class='clickable'><a href='#details'>Details</a></li>\n<li class='clickable'><a href='#underwriting'>Underwriting</a></li>\n<li class='clickable'><a href='#payment'>Payment</a></li>\n<li class='clickable'><a href='#uploads'>Uploads</a></li>\n<li class='clickable'><a href='#summary'>Summary</a></li>\n</ol>",
        },
        {
          id: 'your-details',
          type: 'list',
          readonly: false,
          label: 'Your Details',
          uid: '149ba33c3dcef1de',
          content: [
            {
              id: 'system-constant',
              type: 'html',
              readonly: true,
              label: '',
              uid: '3335dba07bda188d',
              content: '<p>What is your Date of Birth</p>\n',
            },
            {
              id: 'date-of-birth',
              type: 'component',
              elementType: 'input',
              readonly: false,
              label: 'Date Of Birth',
              uid: 'f0e0a8a867c4bd0c',
              content: '',
              publishChange: true,
              errors: ['*'],
              inputType: 'date',
              component: 'DateSelector',
              maxYear: 2023,
              minYear: 1923,
            },
          ],
        },
        {
          id: 'policy-term',
          type: 'list',
          readonly: false,
          label: 'Policy Term',
          uid: '35f4d5729fdf6600',
          content: [
            {
              id: 'system-constant',
              type: 'html',
              readonly: true,
              label: '',
              uid: 'fa112b5ef48e86e7',
              content:
                '<p>How long do you want the term of the policy to be</p>\n',
            },
            {
              id: 'term-in-years',
              type: 'integer',
              elementType: 'select',
              readonly: false,
              label: 'Term In Years',
              uid: 'b16016e2ea02626a',
              content: '',
              publishChange: true,
              errors: ['*'],
              options: [
                '',
                '7',
                '8',
                '9',
                '10',
                '11',
                '12',
                '13',
                '14',
                '15',
                '16',
                '17',
                '18',
              ],
            },
          ],
        },
        {
          id: 'investment-contribution',
          type: 'list',
          readonly: false,
          label: 'Investment Contribution',
          uid: '8f94572b4939c1ac',
          content: [
            {
              id: 'system-constant',
              type: 'html',
              readonly: true,
              label: '',
              uid: 'ba35bd867edc1646',
              content:
                '<p>How much would you like to invest towards the Education of your child</p>\n',
            },
            {
              id: 'educare-premium',
              type: 'amount',
              elementType: 'input',
              readonly: false,
              label: 'Educare Premium',
              uid: 'd8f0b000e64c458a',
              content: '',
              publishChange: true,
              errors: ['*'],
              inputType: 'number',
            },
          ],
        },
        {
          id: 'premium-update',
          type: 'list',
          readonly: false,
          label: 'Premium Update',
          uid: 'ae4fd9fd7b90418c',
          content: [
            {
              id: 'system-constant',
              type: 'html',
              readonly: true,
              label: '',
              uid: 'c4fcdafadf238f88',
              content:
                '<p>Do you want to add automatic annual payment and benefit increases. If so - please select the level of payment rate increase you want to apply</p>\n',
            },
            {
              id: 'update-option',
              type: 'field',
              elementType: 'select',
              readonly: false,
              label: 'Update Option',
              uid: 'c4994d80641979e0',
              content: '',
              publishChange: true,
              errors: ['*'],
              options: ['', '0', '5', '10', '15', '20', '25', '30'],
            },
          ],
        },
        {
          id: 'quotation-panel',
          type: 'list',
          readonly: true,
          label: 'Quotation Panel',
          uid: 'c742e7d68cebf913',
          content: [
            {
              id: 'quotation-information',
              type: 'list',
              readonly: true,
              label: 'Quotation Information',
              uid: 'aad0b94f6b1b62b4',
              content: [
                {
                  id: 'system-constant',
                  type: 'html',
                  readonly: true,
                  label: '',
                  uid: '7fb5c584c18de5e7',
                  content: '<p>Educare Plan <strong>Plus</strong></p>\n',
                },
                {
                  id: 'premium',
                  type: 'amount',
                  elementType: 'input',
                  readonly: true,
                  label: 'Premium',
                  uid: '2e71b32f3296dcea',
                  content: '0.00',
                  inputType: 'number',
                },
              ],
            },
          ],
        },
      ],
    },
    title: 'Public Access \n Welcome to Elac Gambia',
    context: 'Educare Enhanced Quotation Details',
    uri: 'educare-plus/quote',
    class: 'educare-enhanced-quotation-details',
  },
  'personal-details': {
    text: [],
    buttons: [
      {
        label: 'Next',
        id: 'next',
        uid: '26ca340069ec84e5',
        control: true,
      },
      {
        label: 'Back',
        id: 'back',
        uid: '1303e2b2da021d46',
        control: true,
      },
      {
        label: 'Cancel',
        id: 'cancel',
        uid: 'e25c3188a733209e',
        control: true,
      },
    ],
    item: {
      id: 'personal-details',
      type: 'list',
      readonly: false,
      label: 'Personal Details',
      uid: '205f148267b4cf8e',
      content: [
        {
          id: 'system-constant',
          type: 'html',
          readonly: true,
          label: '',
          uid: '8606832ab6ae2e45',
          content: '<h2>Personal details</h2>\n',
        },
        {
          id: 'system-constant',
          type: 'html',
          readonly: true,
          label: '',
          uid: 'a8902e1cd0cee07e',
          content:
            '<p>For us to complete the purchase, we require the following information</p>\n',
        },
        {
          id: 'output-html',
          type: 'html',
          readonly: true,
          label: 'Output',
          uid: '3dfdc74464a34aa8',
          content:
            "<ol class='progress-indicator'>\n<li class='done clickable'><a href='#cover'>Cover</a></li>\n<li class='done clickable'><a href='#details'>Details</a></li>\n<li class='clickable'><a href='#underwriting'>Underwriting</a></li>\n<li class='clickable'><a href='#payment'>Payment</a></li>\n<li class='clickable'><a href='#uploads'>Uploads</a></li>\n<li class='clickable'><a href='#summary'>Summary</a></li>\n</ol>",
        },
        {
          id: 'your-details',
          type: 'list',
          readonly: false,
          label: 'Your Details',
          uid: 'b54456c0360901ca',
          content: [
            {
              id: 'first-names',
              type: 'field',
              elementType: 'input',
              readonly: false,
              label: 'First Names',
              uid: 'b0cb5e2013562d51',
              content: '',
              publishChange: true,
              errors: ['*'],
              inputType: 'text',
            },
            {
              id: 'surname',
              type: 'field',
              elementType: 'input',
              readonly: false,
              label: 'Surname',
              uid: 'd9b3dd2f9b758c95',
              content: '',
              publishChange: true,
              errors: ['*'],
              inputType: 'text',
            },
            {
              id: 'idpassport-number',
              type: 'field',
              elementType: 'input',
              readonly: false,
              label: 'ID/Passport Number',
              uid: '98e2671fcd68feeb',
              content: '',
              publishChange: true,
              inputType: 'text',
            },
            {
              id: 'date-of-birth',
              type: 'integer',
              elementType: 'input',
              readonly: true,
              label: 'Date Of Birth',
              uid: 'af59285b1b5638ec',
              content: '19900606',
              inputType: 'number',
            },
            {
              id: 'title',
              type: 'field',
              elementType: 'select',
              readonly: false,
              label: 'Title',
              uid: 'df961919bc248ac4',
              content: '',
              publishChange: true,
              errors: ['*'],
              tooltip: 'Title',
              options: [
                '',
                'Adv',
                'Dr',
                'Miss',
                'Mr',
                'Mrs',
                'Ms',
                'Prof',
                'Rev',
              ],
            },
            {
              id: 'marital-status',
              type: 'field',
              elementType: 'select',
              readonly: false,
              label: 'Marital Status',
              uid: 'b7787bd9a19e2138',
              content: '',
              publishChange: true,
              options: [
                '',
                'Divorced',
                'Married',
                'Single',
                'Widow',
                'Widower',
              ],
            },
            {
              id: 'nationality',
              type: 'field',
              elementType: 'select',
              readonly: false,
              label: 'Nationality',
              uid: '81b86213b18ca43e',
              content: '',
              publishChange: true,
              errors: ['*'],
              tooltip: 'Nationality',
              options: [
                '',
                'Afghan',
                'Albanian',
                'Algerian',
                'American',
                'Andorran',
                'Angolan',
                'Argentine',
                'Armenian',
                'Aromanian',
                'Aruban',
                'Australian',
                'Austrian',
                'Azerbaijani',
                'Bahamian',
                'Bahraini',
                'Baltic German',
                'Baltic Russian',
                'Bangladeshi',
                'Barbadian',
                'Belarusian',
                'Belgian',
                'Belizean',
                'Bermudian',
                'Boer',
                'Bosniak',
                'Brazilian',
                'Breton',
                'British',
                'British Virgin Islander',
                'Bruneian',
                'Bulgarian',
                'Burkinabè',
                'Burundian',
                'Cambodian',
                'Cameroonian',
                'Canadian',
                'Cape Verdean',
                'Catalan',
                'Chadian',
                'Chilean',
                'Chinese',
                'Colombian',
                'Comorian',
                'Congolese',
                'Croatian',
                'Cuban',
                'Cypriot',
                'Czech',
                'Dane',
                'Dominican (Commonwealth)',
                'Dominican (Republic)',
                'Dutch',
                'East Timorese',
                'Ecuadorian',
                'Egyptian',
                'Emirati',
                'English',
                'Eritrean',
                'Estonian',
                'Ethiopian',
                'Falkland Islander',
                'Faroese',
                'Fijian',
                'Filipino',
                'Finn',
                'Finnish Swedish',
                'French Citizen',
                'Gambian',
                'Georgian',
                'German',
                'Ghanaian',
                'Gibraltar',
                'Greek',
                'Greek Macedonian',
                'Grenadian',
                'Guatemalan',
                'Guianese (French)',
                'Guinea-Bissau National',
                'Guinean',
                'Guyanese',
                'Haitian',
                'Honduran',
                'Hong Kong',
                'Hungarian',
                'I-Kiribati',
                'Icelander',
                'Indian',
                'Indonesian',
                'Iranian',
                'Iraqi',
                'Irish',
                'Israeli',
                'Italian',
                'Ivoirian',
                'Jamaican',
                'Japanese',
                'Jordanian',
                'Kazakh',
                'Kenyan',
                'Korean',
                'Kosovar',
                'Kurd',
                'Kuwaiti',
                'Kyrgyz',
                'Lao',
                'Latvian',
                'Lebanese',
                'Liberian',
                'Libyan',
                'Liechtensteiner',
                'Lithuanian',
                'Luxembourger',
                'Macedonian',
                'Macedonian Bulgarian',
                'Malagasy',
                'Malawian',
                'Malaysian',
                'Maldivian',
                'Malian',
                'Maltese',
                'Manx',
                'Mauritian',
                'Mexican',
                'Moldovan',
                'Mongolian',
                'Montenegrin',
                'Moroccan',
                'Namibian',
                'Nepalese',
                'New Zealander',
                'Nicaraguan',
                'Nigerian',
                'Nigerien',
                'Norwegian',
                'Other',
                'Pakistani',
                'Palauan',
                'Palestinian',
                'Panamanian',
                'Papua New Guinean',
                'Paraguayan',
                'Peruvian',
                'Pole',
                'Portuguese',
                'Puerto Rican',
                'Qatari',
                'Romanian',
                'Russian',
                'Rwandan',
                'Salvadoran',
                'Saudi',
                'Scot',
                'Senegalese',
                'Serb',
                'Sierra Leonean',
                'Sindhian',
                'Singaporean',
                'Slovak',
                'Slovene',
                'Somali',
                'Somalilander',
                'South African',
                'Spaniard',
                'Sri Lankan',
                'St Lucian',
                'Sudanese',
                'Surinamese',
                'Swede',
                'Swiss',
                'Syriac',
                'Syrian',
                'São Tomé And Príncipe',
                'Taiwanese',
                'Tajik',
                'Tanzanian',
                'Thai',
                'Tibetan',
                'Tobagonian',
                'Trinidadian',
                'Tunisian',
                'Turk',
                'Turkish Cypriot',
                'Tuvaluan',
                'Ugandan',
                'Ukrainian',
                'United States',
                'Uruguayan',
                'Uzbek',
                'Vanuatuan',
                'Venezuelan',
                'Vietnamese',
                'Welsh',
                'Yemeni',
                'Zambian',
                'Zimbabwean',
              ],
            },
            {
              id: 'gender',
              type: 'field',
              elementType: 'select',
              readonly: false,
              label: 'Gender',
              uid: '72e95c05ec4cf91a',
              content: '',
              publishChange: true,
              errors: ['*'],
              tooltip: 'Gender',
              options: ['', 'Female', 'Male'],
            },
            {
              id: 'occupation',
              type: 'field',
              elementType: 'select',
              readonly: false,
              label: 'Occupation',
              uid: 'abb96c574f579ca2',
              content: '',
              publishChange: true,
              errors: ['*'],
              options: [
                '',
                'Abattoir Worker',
                'Accountant',
                'Accounting Officer',
                'Actor',
                'Actress',
                'Actuary',
                'Admin Assistant',
                'Administrator',
                'Advertising Agent',
                'Advocate',
                'Affal Washer',
                'Agriculture Assistant',
                'Agriculture Officer',
                'Air Force',
                'Air Force Ground Crew',
                'Aircraft Engineer',
                'Airline Employee',
                'Airline Executive',
                'Airline Pilot',
                'Ambulance Attendant',
                'Ambulance Driver',
                'Announcer',
                'Artisan',
                'Artist',
                'Assessor',
                'Auctioneer',
                'Auditor',
                'Author',
                'Auto Electrician',
                'Auxillary Worker',
                'Aviation',
                'Baker',
                'Bank Teller',
                'Bank-Manager',
                'Banker',
                'Bar Lady',
                'Bar Steward',
                'Barber',
                'Barmaid',
                'Barman',
                'Beautician',
                'Blacksmith',
                'Blockman',
                'Boilermaker',
                'Bookbinder',
                'Bookkeeper',
                'Bookmaker',
                'Boring Contractor',
                'Bottle Store Assistant',
                'Bottle Store Manager',
                'Boxer',
                'Bricklayer',
                'Broker',
                'Builder',
                'Building Contractor',
                'Building Foreman',
                'Building Inspector',
                'Bus Driver',
                'Butcher',
                'Buyer',
                'Cabinetmaker',
                'Cafe Owner',
                'Cameraman',
                'Cardiologist',
                'Caretaker',
                'Carpenter',
                'Carpet Layer',
                'Cashier',
                'Caterer',
                'Checker',
                'Chef',
                'Chief Officer',
                'Chiropodist',
                'Civil Defense Officer',
                'Cleaner',
                'Clergyman',
                'Clerk',
                'Cold Storage Worker',
                'Commercial Photographer',
                'Compositor-Electronic',
                'Compositor-Mechanical',
                'Computer Engineer',
                'Computer Operator',
                'Computer Programmer',
                'Computer Systems Analyst',
                'Computer Technician',
                'Conductor',
                'Constable',
                'Construction Worker',
                'Consultant',
                'Cook',
                'Copywriter',
                'Councillor',
                'Courier',
                'Cropduster',
                'Croupier',
                'Customs Official',
                'Dairy Worker',
                'Dancing Teacher',
                'Dean',
                'Demolition Worker',
                'Dental Assistant',
                'Dental Technician',
                'Dentist',
                'Detective',
                'Diamond Cutter',
                'Dietician',
                'Director',
                'Display Artist',
                'Diver',
                'Dockworker',
                'Doctor',
                'Domestic',
                'Draughtsman',
                'Dressmaker',
                'Driller',
                'Driver',
                'Dry Cleaner',
                'Economist',
                'Editor',
                'Education Inspector',
                'Educator',
                'Electrical Contractor',
                'Electrician',
                'Electronics Engineer',
                'Electroplater',
                'Employee',
                'Engineer',
                'Engineer-Qualified',
                'Engraver',
                'Entomologist',
                'Estate Agent',
                'Executive',
                'Explosives Worker',
                'Exterminator',
                'Farm Worker',
                'Farmer Foreman',
                'Farmer Manager',
                'Farmer Owner',
                'Fashion Designer',
                'Film Maker',
                'Fireman',
                'Fisherman',
                'Fitter And Turner',
                'Floor Surfacer',
                'Florist',
                'Foreman',
                'Forester',
                'Fortune Teller',
                'Foundry Manager',
                'Foundry Worker',
                'Freight Forwarder',
                'Fumigator',
                'Furnace Assistant',
                'Furnace Manager',
                'Furnace Supervisor',
                'Game Ranger',
                'Game Warden',
                'Garage Attendant',
                'Garage Owner',
                'Gardener',
                'General Assistant',
                'General Dealer',
                'General Worker',
                'Geologist',
                'Glassblower',
                'Glazier',
                'Goldsmith',
                'Grain Silo Operator',
                'Graphic Designer',
                'Greengrocer',
                'Groundsman',
                'Guard (Security)',
                'Gunsmith',
                'Hairdresser',
                'Hawker',
                'Head Of Department',
                'Headmaster',
                'Health Inspector',
                'Homeopath',
                'Horticulturist',
                'Hotel Manager',
                'Housewife',
                'Hr Assistant',
                'Inspector',
                'Insurance Agent',
                'Insurance Assessor',
                'Interior Decorator',
                'Interpreter',
                'Investigator',
                'Jeweller',
                'Jockey',
                'Joiner',
                'Journalist',
                'Labourer',
                'Lawyer',
                'Lecturer',
                'Librarian',
                'Lift Inspector',
                'Lift Operator',
                'Lift Technician',
                'Lighthouse Keeper',
                'Locksmith',
                'Machine Operator',
                'Machinist',
                'Magistrate',
                'Major',
                'Manager',
                'Manicurist',
                'Manufacturer',
                'Market Researcher',
                'Marketing Officer',
                'Martial Arts Instructor',
                'Mason',
                'Meat Inspector',
                'Mechanic',
                'Medical Representative',
                'Medical Technologist',
                'Medical-Practitioner',
                'Merchandiser',
                'Merchant Seaman',
                'Messenger',
                'Metallurgist',
                'Meter Reader',
                'Midwife',
                'Millwright',
                'Miner',
                'Minister Of Religion',
                'Model',
                'Motor Salesman',
                'Motor Spares Salesman',
                'Moulder',
                'Musician',
                'Naval Personnel',
                'Night Watchman',
                'Nurse',
                'Nurseryman',
                'Nursing Assistant',
                'Nursing Sister',
                'Occupational Therapist',
                'Officer',
                'Operator',
                'Ophthalmologist',
                'Optical Mechanic',
                'Orthopedic Orthopedist',
                'Packer',
                'Painter',
                'Painting Contractor',
                'Panelbeater',
                'Paramedic',
                'Pastor',
                'Pawnbroker',
                'Pensioner',
                'Permanent Way',
                'Personnel Officer',
                'Pest Exterminator',
                'Petrol Attendant',
                'Petroleum Refinery',
                'Pharmacist',
                'Photographer',
                'Physiotherapist',
                'Piano Tuner',
                'Pilot',
                'Plasterer',
                'Plumber',
                'Police Constable',
                'Police Woman',
                'Policeman',
                'Porter',
                'Postman',
                'Postmaster',
                'Principal',
                'Printer',
                'Prisons Personnel',
                'Private Investigator',
                'Producer',
                'Professor',
                'Programmer',
                'Property Appraiser',
                'Proprietor',
                'Public Prosecutor',
                'Public Relations Officer',
                'Quality Controller',
                'Quantity Surveyor',
                'Quarryman',
                'Racehorse Trainer',
                'Radiographer',
                'Radiologist',
                'Receptionist',
                'Religious Leader',
                'Repairman',
                'Representative',
                'Research Assistant',
                'Restaurant Owner',
                'Retired',
                'Rigger (Docks)',
                'Roadworker',
                'Sailor',
                'Sales Consultant',
                'Sales Lady',
                'Sales Support',
                'Sales Woman',
                'Salesman',
                'Seaman',
                'Seaman (Merchant)',
                'Seamstress',
                'Secretary',
                'Security Guard',
                'Self-Employed',
                'Sergeant',
                'Shedman',
                'Sheet Metal Worker',
                'Shipping Agent',
                'Shop Assistant',
                'Shop Manager',
                'Shopfitter',
                'Shunter-Foreman',
                'Signwriter',
                'Silversmith',
                'Singer',
                'Slaughterer',
                'Smelter',
                'Social Worker',
                'Soldier',
                'Sound Engineer',
                'Sportsman',
                'Sportswoman',
                'Spray Painter',
                'Sprayer',
                'Stationmaster',
                'Statistician',
                'Stevedore',
                'Steward',
                'Stock Broker',
                'Store Lady',
                'Storeman',
                'Student',
                'Supervisor',
                'Surgeon',
                'Sweeper',
                'Swimming Pool Attendant',
                'Switchboard Operator',
                'Systems Analyst',
                'Tailor',
                'Taxi Driver',
                'Teacher',
                'Technician',
                'Telegraphist',
                'Telephone Technician',
                'Telephonist',
                'Tiler',
                'Toolmaker',
                'Town Clerk',
                'Town Planner',
                'Trader',
                'Tradesman',
                'Traffic Officer',
                'Trainer',
                'Translator',
                'Travel Agent',
                'Typesetter',
                'Typist',
                'Undertaker',
                'Underwriter',
                'Unemployed',
                'Upholsterer',
                'Urologist',
                'Vetrinarian',
                'Vetrinary Surgeon',
                'Vulcaniser',
                'Waiter',
                'Waitress',
                'Warden',
                'Watch Maker',
                'Watchman',
                'Welder',
                'Well Sinker',
                'Window Cleaner',
                'Window Dresser',
                'Wood Cutter',
                'Worker',
                'Wrestler',
              ],
            },
          ],
        },
        {
          id: 'your-contact-details',
          type: 'list',
          readonly: false,
          label: 'Your Contact Details',
          uid: '138b6387216670d0',
          content: [
            {
              id: 'cell-phone',
              type: 'component',
              elementType: 'input',
              readonly: false,
              label: 'Cell Phone',
              uid: '43bb2ffb6f6594b6',
              content: '',
              publishChange: true,
              errors: ['*'],
              inputType: 'tel',
              component: 'PhoneNumber',
            },
            {
              id: 'whatsapp-phone',
              type: 'component',
              elementType: 'input',
              readonly: false,
              label: 'Whatsapp Phone',
              uid: '19c16379558a23ed',
              content: '',
              publishChange: true,
              inputType: 'tel',
              component: 'PhoneNumber',
            },
            {
              id: 'email',
              type: 'field',
              elementType: 'input',
              readonly: false,
              label: 'Email',
              uid: '99e501a1eafa8e29',
              content: '',
              publishChange: true,
              errors: ['*'],
              inputType: 'email',
            },
          ],
        },
        {
          id: 'your-postal-address',
          type: 'list',
          readonly: false,
          label: 'Your Postal Address',
          uid: '2eb0075d48672657',
          content: [
            {
              id: 'life-assured-postal-address',
              type: 'list',
              readonly: false,
              label: 'Life Assured Postal Address',
              uid: '11c2de8188fd8d1b',
              content: [
                {
                  id: 'addressee',
                  type: 'field',
                  elementType: 'input',
                  readonly: false,
                  label: 'Addressee',
                  uid: 'e301c545cb714ab7',
                  content: '',
                  publishChange: true,
                  inputType: 'text',
                },
                {
                  id: 'po-box-or-private-bag',
                  type: 'field',
                  elementType: 'input',
                  readonly: false,
                  label: 'PO Box Or Private Bag',
                  uid: '438a76adb0dd6ffa',
                  content: '',
                  publishChange: true,
                  inputType: 'text',
                },
                {
                  id: 'area-code',
                  type: 'field',
                  elementType: 'datalist',
                  readonly: false,
                  label: 'Area Code',
                  uid: '397fc310b06a8777',
                  content: '',
                  publishChange: true,
                  options: [],
                  partialOptions: true,
                  autoComplete: 'options',
                },
                {
                  id: 'street',
                  type: 'field',
                  elementType: 'input',
                  readonly: false,
                  label: 'Street',
                  uid: '14ca763eb4f00bc6',
                  content: '',
                  publishChange: true,
                  inputType: 'text',
                },
                {
                  id: 'suburb',
                  type: 'field',
                  elementType: 'input',
                  readonly: false,
                  label: 'Suburb',
                  uid: 'c65b69006d719e9f',
                  content: '',
                  publishChange: true,
                  inputType: 'text',
                },
                {
                  id: 'town',
                  type: 'field',
                  elementType: 'select',
                  readonly: false,
                  label: 'Town',
                  uid: '869fa1a1bea8499a',
                  content: '',
                  publishChange: true,
                  errors: ['*'],
                  options: [
                    '',
                    'Abuko Village',
                    'Albreda',
                    'Bakau',
                    'Bakau Cape Point',
                    'Bakoteh',
                    'Banjul',
                    'Banjulingding',
                    'Bansang',
                    'Barra',
                    'Basse',
                    'Bijilo',
                    'Bintang',
                    'Brefet',
                    'Brikama',
                    'Brikama Ba',
                    'Brikama Sanchaba',
                    'Brufut',
                    'Brusubi',
                    'Brusumbala',
                    'Bundung',
                    'Bwiam',
                    'Churchills Town',
                    'Dimbaya',
                    'Ebo Town',
                    'Essau',
                    'Fajara',
                    'Faji Kunda',
                    'Faraba Banta',
                    'Farafenni',
                    'Farato',
                    'Fatoto',
                    'Foni Berefet',
                    'Foni Bondali',
                    'Ghana Town',
                    'Gunjur',
                    'Jakaba',
                    'Jambanjali',
                    'Jambur',
                    'James Island',
                    'Janjangbureh',
                    'Jeshwang',
                    'Kafuta',
                    'Kairaba Avenue',
                    'Kalagie',
                    'Kanifing',
                    'Kanifing Industrial Estate',
                    'Kanilai',
                    'Kartong',
                    'Kaur',
                    'Kembujeh',
                    'Kerewan',
                    'Kerr Serign',
                    'Kololi',
                    'Koloro Village',
                    'Kotu',
                    'Kotu Quarry',
                    'Kudang',
                    'Kunkujang Keitaya',
                    'Kunkujang Mariama',
                    'Kuntaur',
                    'Lamin',
                    'Lamin Koto',
                    'Latri Kunda Sabiji',
                    'Latrikunda',
                    'Latrikunda German',
                    'London Corner',
                    'Manjai Kunda',
                    'Mansakonko',
                    'New Jeshwang',
                    'Nyofelleh',
                    'Old Jeshwang',
                    'Pakali Nding',
                    'Sanyang',
                    'Serrenkunda',
                    'Sibanor',
                    'Siffoe',
                    'Sinchu Alhagie Village',
                    'Sinchu Baliya',
                    'Sinchu Sorrie',
                    'Soma',
                    'Sukuta',
                    'Tabokoto',
                    'Tallinding',
                    'Tanji',
                    'Tujering',
                    'Yundum',
                    'Yundum Village',
                    'Yunna Village',
                  ],
                },
                {
                  id: 'region',
                  type: 'field',
                  elementType: 'select',
                  readonly: false,
                  label: 'Region',
                  uid: '2018c28ec820aae9',
                  content: '',
                  publishChange: true,
                  errors: ['*'],
                  options: [
                    '',
                    'BCC',
                    'CRR',
                    'KSMD',
                    'LRR',
                    'NBR',
                    'URR',
                    'WCR',
                  ],
                },
              ],
            },
          ],
        },
        {
          id: 'quotation-panel',
          type: 'list',
          readonly: true,
          label: 'Quotation Panel',
          uid: 'c17188078fec4c93',
          content: [
            {
              id: 'quotation-information',
              type: 'list',
              readonly: true,
              label: 'Quotation Information',
              uid: 'c39d7b9ed0e2d738',
              content: [
                {
                  id: 'system-constant',
                  type: 'html',
                  readonly: true,
                  label: '',
                  uid: 'b817b0b6d23cfc22',
                  content: '<p>Educare Plan <strong>Plus</strong></p>\n',
                },
                {
                  id: 'premium',
                  type: 'amount',
                  elementType: 'input',
                  readonly: true,
                  label: 'Premium',
                  uid: 'd302a75bb5ba94db',
                  content: '315.00',
                  inputType: 'number',
                },
              ],
            },
          ],
        },
      ],
    },
    title: 'Public Access \n Welcome to Elac Gambia',
    context: 'Educare Enhanced Personal Details',
    uri: 'educare-plus/personal-details',
    class: 'educare-enhanced-personal-details',
  },
  'spouse-details': {
    text: [],
    buttons: [
      {
        label: 'Next',
        id: 'next',
        uid: '26ca340069ec84e5',
        control: true,
      },
      {
        label: 'Back',
        id: 'back',
        uid: '1303e2b2da021d46',
        control: true,
      },
      {
        label: 'Cancel',
        id: 'cancel',
        uid: 'e25c3188a733209e',
        control: true,
      },
    ],
    item: {
      id: 'spouse-details',
      type: 'list',
      readonly: false,
      label: 'Spouse Details',
      uid: '9be9aeaec541483d',
      content: [
        {
          id: 'system-constant',
          type: 'html',
          readonly: true,
          label: '',
          uid: 'b214690bf6bc9b1f',
          content: '<h2>Spouse details</h2>\n',
        },
        {
          id: 'system-constant',
          type: 'html',
          readonly: true,
          label: '',
          uid: '1b69ff24ec5e56a9',
          content: '',
        },
        {
          id: 'output-html',
          type: 'html',
          readonly: true,
          label: 'Output',
          uid: 'e55ea46b1a371db4',
          content:
            "<ol class='progress-indicator'>\n<li class='done clickable'><a href='#cover'>Cover</a></li>\n<li class='done clickable'><a href='#details'>Details</a></li>\n<li class='clickable'><a href='#underwriting'>Underwriting</a></li>\n<li class='clickable'><a href='#payment'>Payment</a></li>\n<li class='clickable'><a href='#uploads'>Uploads</a></li>\n<li class='clickable'><a href='#summary'>Summary</a></li>\n</ol>",
        },
        {
          id: 'spouse-identification',
          type: 'list',
          readonly: false,
          label: 'Spouse Identification',
          uid: 'b2ea3d042cbc8026',
          content: [
            {
              id: 'first-names',
              type: 'field',
              elementType: 'input',
              readonly: false,
              label: 'First Names',
              uid: 'e56d649f329f8900',
              content: '',
              publishChange: true,
              errors: ['*'],
              inputType: 'text',
            },
            {
              id: 'surname',
              type: 'field',
              elementType: 'input',
              readonly: false,
              label: 'Surname',
              uid: '06edb783e92ec333',
              content: '',
              publishChange: true,
              errors: ['*'],
              inputType: 'text',
            },
            {
              id: 'id-number',
              type: 'field',
              elementType: 'input',
              readonly: false,
              label: 'ID Number',
              uid: 'd740c4eef19f5cf0',
              content: '',
              publishChange: true,
              inputType: 'text',
            },
            {
              id: 'date-of-birth',
              type: 'component',
              elementType: 'input',
              readonly: false,
              label: 'Date Of Birth',
              uid: '5c9aa3099fa760a5',
              content: '',
              publishChange: true,
              errors: ['*'],
              inputType: 'date',
              component: 'DateSelector',
              maxYear: 2023,
              minYear: 1923,
            },
            {
              id: 'title',
              type: 'field',
              elementType: 'select',
              readonly: false,
              label: 'Title',
              uid: '189ba9973d67c117',
              content: '',
              publishChange: true,
              errors: ['*'],
              options: [
                '',
                'Adv',
                'Dr',
                'Miss',
                'Mr',
                'Mrs',
                'Ms',
                'Prof',
                'Rev',
              ],
            },
            {
              id: 'marital-status',
              type: 'field',
              elementType: 'input',
              readonly: true,
              label: 'Marital Status',
              uid: '5bc24b9435dfcfdb',
              content: 'Married',
              inputType: 'text',
            },
            {
              id: 'gender',
              type: 'field',
              elementType: 'input',
              readonly: true,
              label: 'Gender',
              uid: '46c535151fcf7a9a',
              content: 'Female',
              inputType: 'text',
            },
          ],
        },
        {
          id: 'cell-phone',
          type: 'component',
          elementType: 'input',
          readonly: false,
          label: 'Cell Phone',
          uid: 'da7092304405e32c',
          content: '',
          publishChange: true,
          errors: ['*'],
          inputType: 'tel',
          component: 'PhoneNumber',
        },
        {
          id: 'work-phone',
          type: 'component',
          elementType: 'input',
          readonly: false,
          label: 'Work Phone',
          uid: '62d932db51cdc00b',
          content: '',
          publishChange: true,
          inputType: 'tel',
          component: 'PhoneNumber',
        },
        {
          id: 'system-constant',
          type: 'html',
          readonly: true,
          label: '',
          uid: '105c42d70fbd4e0d',
          content: '<p>Specify your spouse to be the trustee of the plan</p>\n',
        },
        {
          id: 'spouse-is-trustee',
          type: 'field',
          elementType: 'checkbox',
          readonly: false,
          label: 'Spouse Is Trustee',
          uid: '6b58669450576bf8',
          content: '',
          publishChange: true,
          options: ['Y', 'N'],
        },
        {
          id: 'quotation-panel',
          type: 'list',
          readonly: true,
          label: 'Quotation Panel',
          uid: 'bea6d4bbe9767304',
          content: [
            {
              id: 'quotation-information',
              type: 'list',
              readonly: true,
              label: 'Quotation Information',
              uid: 'b4259726abf6bb62',
              content: [
                {
                  id: 'system-constant',
                  type: 'html',
                  readonly: true,
                  label: '',
                  uid: '3aa1209040068726',
                  content: '<p>Educare Plan <strong>Plus</strong></p>\n',
                },
                {
                  id: 'premium',
                  type: 'amount',
                  elementType: 'input',
                  readonly: true,
                  label: 'Premium',
                  uid: 'a4988c3d7f1e31ea',
                  content: '306.00',
                  inputType: 'number',
                },
              ],
            },
          ],
        },
      ],
    },
    title: 'Public Access \n Welcome to Elac Gambia',
    context: 'Educare Enhanced Spouse Details',
    uri: 'educare-plus/spouse-details',
    class: 'educare-enhanced-spouse-details',
  },
  'trustee-details': {
    text: [],
    buttons: [
      {
        label: 'Next',
        id: 'next',
        uid: '26ca340069ec84e5',
        control: true,
      },
      {
        label: 'Back',
        id: 'back',
        uid: '1303e2b2da021d46',
        control: true,
      },
      {
        label: 'Cancel',
        id: 'cancel',
        uid: 'e25c3188a733209e',
        control: true,
      },
    ],
    item: {
      id: 'trustee-details',
      type: 'list',
      readonly: false,
      label: 'Trustee Details',
      uid: '229376e4c6d431e3',
      content: [
        {
          id: 'system-constant',
          type: 'html',
          readonly: true,
          label: '',
          uid: 'c57d8cad196453a3',
          content: '<h2>Trustee details</h2>\n',
        },
        {
          id: 'system-constant',
          type: 'html',
          readonly: true,
          label: '',
          uid: '53952a550bf734cc',
          content: '',
        },
        {
          id: 'output-html',
          type: 'html',
          readonly: true,
          label: 'Output',
          uid: '8ac4d130f3be0edd',
          content:
            "<ol class='progress-indicator'>\n<li class='done clickable'><a href='#cover'>Cover</a></li>\n<li class='done clickable'><a href='#details'>Details</a></li>\n<li class='clickable'><a href='#underwriting'>Underwriting</a></li>\n<li class='clickable'><a href='#payment'>Payment</a></li>\n<li class='clickable'><a href='#uploads'>Uploads</a></li>\n<li class='clickable'><a href='#summary'>Summary</a></li>\n</ol>",
        },
        {
          id: 'trustee-identification',
          type: 'list',
          readonly: false,
          label: 'Trustee Identification',
          uid: 'e93006ff805df4a5',
          content: [
            {
              id: 'first-names',
              type: 'field',
              elementType: 'input',
              readonly: false,
              label: 'First Names',
              uid: 'fffa2dcbe12ce9d3',
              content: '',
              publishChange: true,
              errors: ['*'],
              inputType: 'text',
            },
            {
              id: 'surname',
              type: 'field',
              elementType: 'input',
              readonly: false,
              label: 'Surname',
              uid: 'f0395d772444989c',
              content: '',
              publishChange: true,
              errors: ['*'],
              inputType: 'text',
            },
            {
              id: 'date-of-birth',
              type: 'component',
              elementType: 'input',
              readonly: false,
              label: 'Date Of Birth',
              uid: '8c3344fe2f04f1c2',
              content: '',
              publishChange: true,
              errors: ['*'],
              inputType: 'date',
              component: 'DateSelector',
              maxYear: 2023,
              minYear: 1923,
            },
            {
              id: 'idpassport-number',
              type: 'field',
              elementType: 'input',
              readonly: false,
              label: 'ID Number',
              uid: 'cb7556afc0935168',
              content: '',
              publishChange: true,
              inputType: 'text',
            },
            {
              id: 'gender',
              type: 'field',
              elementType: 'radio',
              readonly: false,
              label: 'Gender',
              uid: 'a67a1833a0e4d00f',
              content: '',
              publishChange: true,
              errors: ['*'],
              options: ['Female', 'Male'],
            },
            {
              id: 'title',
              type: 'field',
              elementType: 'select',
              readonly: false,
              label: 'Title',
              uid: '10e4727a0b4bd062',
              content: '',
              publishChange: true,
              errors: ['*'],
              options: [
                '',
                'Adv',
                'Dr',
                'Miss',
                'Mr',
                'Mrs',
                'Ms',
                'Prof',
                'Rev',
              ],
            },
            {
              id: 'marital-status',
              type: 'field',
              elementType: 'select',
              readonly: false,
              label: 'Marital Status',
              uid: 'd5f39f372e438d24',
              content: '',
              publishChange: true,
              options: [
                '',
                'Divorced',
                'Married',
                'Single',
                'Widow',
                'Widower',
              ],
            },
            {
              id: 'relationship',
              type: 'field',
              elementType: 'select',
              readonly: false,
              label: 'Relationship',
              uid: '494ce5d6e4f659ca',
              content: '',
              publishChange: true,
              errors: ['*'],
              options: [
                '',
                'Daughter',
                'Estate',
                'Father',
                'Father-In-Law',
                'Grandfather',
                'Grandmother',
                'Mother',
                'Mother-In-Law',
                'Son',
                'Spouse',
                'Testament',
                'Trust',
                'Sister',
                'Brother',
              ],
            },
          ],
        },
        {
          id: 'cell-phone',
          type: 'component',
          elementType: 'input',
          readonly: false,
          label: 'Cell Phone',
          uid: '7d6ed2ce591e1ce6',
          content: '',
          publishChange: true,
          errors: ['*'],
          inputType: 'tel',
          component: 'PhoneNumber',
        },
        {
          id: 'work-phone',
          type: 'component',
          elementType: 'input',
          readonly: false,
          label: 'Work Phone',
          uid: '0c493e2307314467',
          content: '',
          publishChange: true,
          inputType: 'tel',
          component: 'PhoneNumber',
        },
        {
          id: 'quotation-panel',
          type: 'list',
          readonly: true,
          label: 'Quotation Panel',
          uid: 'af69be47cb368b57',
          content: [
            {
              id: 'quotation-information',
              type: 'list',
              readonly: true,
              label: 'Quotation Information',
              uid: '247f4802b417f584',
              content: [
                {
                  id: 'system-constant',
                  type: 'html',
                  readonly: true,
                  label: '',
                  uid: 'ec25eeb9793a503b',
                  content: '<p>Educare Plan <strong>Plus</strong></p>\n',
                },
                {
                  id: 'premium',
                  type: 'amount',
                  elementType: 'input',
                  readonly: true,
                  label: 'Premium',
                  uid: '3aa22fa32e1f6ecd',
                  content: '315.00',
                  inputType: 'number',
                },
              ],
            },
          ],
        },
      ],
    },
    title: 'Public Access \n Welcome to Elac Gambia',
    context: 'Educare Enhanced Trustee Details',
    uri: 'educare-plus/trustee-details',
    class: 'educare-enhanced-trustee-details',
  },
  'beneficiary-details': {
    text: [],
    buttons: [
      {
        label: 'Add Trustee',
        id: 'add-trustee',
        uid: '1c40f26fba6a8711',
        control: false,
      },
      {
        label: 'Add Another Beneficiary',
        id: 'add-another-beneficiary',
        uid: 'cca8995092d126c2',
        control: false,
      },
      {
        label: 'Update Benefit Split Percentage',
        id: 'update-benefit-split-percentage',
        uid: '6ce12a7a32a8d69a',
        control: false,
      },
      {
        label: 'Next',
        id: 'next',
        uid: '26ca340069ec84e5',
        control: true,
      },
      {
        label: 'Back',
        id: 'back',
        uid: '1303e2b2da021d46',
        control: true,
      },
      {
        label: 'Cancel',
        id: 'cancel',
        uid: 'e25c3188a733209e',
        control: true,
      },
    ],
    item: {
      id: 'specify-beneficiaries',
      type: 'list',
      readonly: true,
      label: 'Specify Beneficiaries',
      uid: 'b0a77ffa488146c4',
      content: [
        {
          id: 'system-constant',
          type: 'html',
          readonly: true,
          label: '',
          uid: 'ccd1e945cd5c3045',
          content: '<h2>Beneficiary Details</h2>\n',
        },
        {
          id: 'system-constant',
          type: 'html',
          readonly: true,
          label: '',
          uid: 'f0d387b3ecd49ed5',
          content: '<p>Please specify your beneficiary details below</p>\n',
        },
        {
          id: 'output-html',
          type: 'html',
          readonly: true,
          label: 'Output',
          uid: 'f46ff380717f1288',
          content:
            "<ol class='progress-indicator'>\n<li class='done clickable'><a href='#cover'>Cover</a></li>\n<li class='done clickable'><a href='#details'>Details</a></li>\n<li class='clickable'><a href='#underwriting'>Underwriting</a></li>\n<li class='clickable'><a href='#payment'>Payment</a></li>\n<li class='clickable'><a href='#uploads'>Uploads</a></li>\n<li class='clickable'><a href='#summary'>Summary</a></li>\n</ol>",
        },
        {
          id: 'system-field-outcome',
          type: 'html',
          readonly: true,
          label: '',
          uid: '6c60b1c422ae8373',
          content:
            "<p><a href='#add-trustee'>Add Trustee as beneficiary</a></p>\n",
        },
        {
          id: 'system-constant',
          type: 'html',
          readonly: true,
          label: '',
          uid: '145b199f80e0c8c3',
          content:
            "<p><a href='#add-another-beneficiary'>Add a different beneficiary</a></p>\n",
        },
        {
          id: 'beneficiary-details',
          type: 'table',
          readonly: true,
          label: 'Beneficiary Details',
          uid: 'dffb42bbd103d694',
          triggers: ['DoubleClick'],
          content: [],
        },
        {
          id: 'quotation-panel',
          type: 'list',
          readonly: true,
          label: 'Quotation Panel',
          uid: '7c3aa278efc29ec2',
          content: [
            {
              id: 'quotation-information',
              type: 'list',
              readonly: true,
              label: 'Quotation Information',
              uid: 'ac88fbc805e2ecec',
              content: [
                {
                  id: 'system-constant',
                  type: 'html',
                  readonly: true,
                  label: '',
                  uid: 'ac4e7d07a2c8e4f3',
                  content: '<p>Educare Plan <strong>Plus</strong></p>\n',
                },
                {
                  id: 'premium',
                  type: 'amount',
                  elementType: 'input',
                  readonly: true,
                  label: 'Premium',
                  uid: '73d064eb1a0df579',
                  content: '315.00',
                  inputType: 'number',
                },
              ],
            },
          ],
        },
      ],
    },
    title: 'Public Access \n Welcome to Elac Gambia',
    context: 'Educare Enhanced Beneficiary Details',
    uri: 'educare-plus/beneficiary-details',
    class: 'educare-enhanced-beneficiary-details',
  },
  'add-beneficiary': {
    modal: true,
    text: [],
    buttons: [
      {
        label: 'Recalculate',
        id: 'recalculate',
        uid: 'f7e7fcc76ead3671',
        control: false,
      },
      {
        label: 'Add',
        id: 'add',
        uid: '1413145eedf0be58',
        control: true,
      },
      {
        label: 'Back',
        id: 'back',
        uid: '1303e2b2da021d46',
        control: true,
      },
    ],
    item: {
      id: 'beneficiary-details',
      type: 'list',
      readonly: false,
      label: 'Beneficiary Details',
      uid: '404c80c11a91d637',
      content: [
        {
          id: 'system-constant',
          type: 'html',
          readonly: true,
          label: '',
          uid: '94e325bd80270712',
          content:
            '<p>Please provide the following information for the beneficiary you would like to add to the policy</p>\n',
        },
        {
          id: 'first-names',
          type: 'field',
          elementType: 'input',
          readonly: false,
          label: 'First Names',
          uid: 'a4609b3e81a15b64',
          content: '',
          publishChange: true,
          errors: ['*'],
          inputType: 'text',
        },
        {
          id: 'surname',
          type: 'field',
          elementType: 'input',
          readonly: false,
          label: 'Surname',
          uid: 'd7eda3e624e178e2',
          content: '',
          publishChange: true,
          errors: ['*'],
          inputType: 'text',
        },
        {
          id: 'role',
          type: 'field',
          elementType: 'select',
          readonly: false,
          label: 'Role',
          uid: '8ca3d9f9ca35bf9f',
          content: '',
          publishChange: true,
          errors: ['*'],
          options: [
            '',
            'Brother',
            'Child',
            'Cousin',
            'Daughter',
            'Family',
            'Father',
            'Father-in-law',
            'Grandchild',
            'Grandfather',
            'Grandmother',
            'Main',
            'Mother',
            'Mother-in-law',
            'Nephew',
            'Niece',
            'Sister',
            'Son',
            'Spouse',
            'Step-Father',
            'Step-Mother',
          ],
        },
        {
          id: 'gender',
          type: 'field',
          elementType: 'radio',
          readonly: false,
          label: 'Gender',
          uid: '09f28932184006c8',
          content: '',
          publishChange: true,
          errors: ['*'],
          options: ['Female', 'Male'],
        },
        {
          id: 'date-of-birth',
          type: 'component',
          elementType: 'input',
          readonly: false,
          label: 'Date Of Birth',
          uid: '6b9a4f659b85294a',
          content: '',
          publishChange: true,
          errors: ['*'],
          inputType: 'date',
          component: 'DateSelector',
          maxYear: 2023,
          minYear: 1923,
        },
      ],
    },
    title: 'Public Access \n Welcome to Elac Gambia',
    context: 'Beneficiary Details Add',
    uri: 'application/beneficiary-details/add',
    class: 'beneficiary-details-add',
  },
  'edit-beneficiary': {
    modal: true,
    text: [],
    buttons: [
      {
        label: 'Recalculate',
        id: 'recalculate',
        uid: 'f7e7fcc76ead3671',
        control: false,
      },
      {
        label: 'Delete Beneficiary',
        id: 'delete-beneficiary',
        uid: '02308b457a7dc6a3',
        control: false,
      },
      {
        label: 'Update',
        id: 'update',
        uid: 'd8035e4553f65492',
        control: true,
      },
    ],
    item: {
      id: 'beneficiary-details',
      type: 'list',
      readonly: false,
      label: 'Beneficiary Details',
      uid: '0ae7a998ec513728',
      content: [
        {
          id: 'system-constant',
          type: 'html',
          readonly: true,
          label: '',
          uid: '7fa0e505ce6ab73a',
          content:
            "<p>Edit beneficiary information below or <a href='#delete-beneficiary'>click here to remove beneficiary</a>.</p>\n",
        },
        {
          id: 'first-names',
          type: 'field',
          elementType: 'input',
          readonly: false,
          label: 'First Names',
          uid: 'a4609b3e81a15b64',
          content: 'Sdf',
          publishChange: true,
          inputType: 'text',
        },
        {
          id: 'surname',
          type: 'field',
          elementType: 'input',
          readonly: false,
          label: 'Surname',
          uid: 'd7eda3e624e178e2',
          content: 'Dsf',
          publishChange: true,
          inputType: 'text',
        },
        {
          id: 'role',
          type: 'field',
          elementType: 'select',
          readonly: false,
          label: 'Role',
          uid: '8ca3d9f9ca35bf9f',
          content: 'BROTHER-1',
          publishChange: true,
          options: [
            '',
            'Brother',
            'Child',
            'Cousin',
            'Daughter',
            'Family',
            'Father',
            'Father-in-law',
            'Grandchild',
            'Grandfather',
            'Grandmother',
            'Main',
            'Mother',
            'Mother-in-law',
            'Nephew',
            'Niece',
            'Sister',
            'Son',
            'Spouse',
            'Step-Father',
            'Step-Mother',
          ],
        },
        {
          id: 'gender',
          type: 'field',
          elementType: 'select',
          readonly: false,
          label: 'Gender',
          uid: '09f28932184006c8',
          content: 'Male',
          publishChange: true,
          options: ['', 'Female', 'Male'],
        },
        {
          id: 'date-of-birth',
          type: 'component',
          elementType: 'input',
          readonly: false,
          label: 'Date Of Birth',
          uid: '6b9a4f659b85294a',
          content: '19930405',
          publishChange: true,
          inputType: 'date',
          component: 'DateSelector',
          maxYear: 2023,
          minYear: 1923,
        },
      ],
    },
    title: 'Public Access \n Welcome to Elac Gambia',
    context: 'Beneficiary Details Edit',
    uri: 'application/beneficiary-details/edit',
    class: 'beneficiary-details-edit',
  },
  'update-beneficiary-split-percentage': {
    modal: true,
    text: [],
    buttons: [
      {
        label: 'Update',
        id: 'update',
        uid: 'd8035e4553f65492',
        control: true,
      },
    ],
    item: {
      id: 'update-benefit-split-percentage',
      type: 'list',
      readonly: false,
      label: 'Update Benefit Split Percentage',
      uid: '92b7c2df343dd4ea',
      content: [
        {
          id: 'beneficiary1',
          type: 'list',
          readonly: false,
          label: 'Beneficiary-1',
          uid: '380097e151f99620',
          content: [
            {
              id: 'system-constant',
              type: 'html',
              readonly: true,
              label: '',
              uid: '9835ef823c13cade',
              content: '<p>Df</p>\n',
            },
            {
              id: 'beneficiary-split',
              type: 'field',
              elementType: 'input',
              readonly: false,
              label: 'Beneficiary Split',
              uid: '8644e07220d3617d',
              content: '50.00',
              publishChange: true,
              inputType: 'text',
            },
          ],
        },
        {
          id: 'beneficiary2',
          type: 'list',
          readonly: false,
          label: 'Beneficiary-2',
          uid: '4c489687ae291281',
          content: [
            {
              id: 'system-constant',
              type: 'html',
              readonly: true,
              label: '',
              uid: 'f9b55b94d2579067',
              content: '<p>Sdf</p>\n',
            },
            {
              id: 'beneficiary-split',
              type: 'field',
              elementType: 'input',
              readonly: false,
              label: 'Beneficiary Split',
              uid: '73580f51265660b7',
              content: '50.00',
              publishChange: true,
              inputType: 'text',
            },
          ],
        },
      ],
    },
    title: 'Public Access \n Welcome to Elac Gambia',
    context: 'Beneficiary Details Split',
    uri: 'application/beneficiary-details/split',
    class: 'beneficiary-details-split',
  },
  'medical-underwriting': {
    text: [],
    buttons: [
      { label: 'Next', id: 'next', uid: '26ca340069ec84e5', control: true },
      { label: 'Back', id: 'back', uid: '1303e2b2da021d46', control: true },
      { label: 'Cancel', id: 'cancel', uid: 'e25c3188a733209e', control: true },
    ],
    item: {
      id: 'medical-declaration',
      type: 'list',
      readonly: false,
      label: 'Medical Declaration',
      uid: 'dcd910c2e0abec06',
      content: [
        {
          id: 'system-constant',
          type: 'html',
          readonly: true,
          label: '',
          uid: '809b1b2bab8c1560',
          content: '<h2 id="medical-underwriting">Medical underwriting</h2>\n',
        },
        {
          id: 'system-constant',
          type: 'html',
          readonly: true,
          label: '',
          uid: '8cb5b9f7f893ab25',
          content: '',
        },
        {
          id: 'output-html',
          type: 'html',
          readonly: true,
          label: 'Output Html',
          uid: '7dd45a20ec98eb62',
          content:
            '<ol class="progress-indicator">\n<li class="done clickable"><a href="#cover">Cover</a></li>\n<li class="done clickable"><a href="#details">Details</a></li>\n<li class="done clickable"><a href="#underwriting">Underwriting</a></li>\n<li class="clickable"><a href="#payment">Payment</a></li>\n<li class="clickable"><a href="#uploads">Uploads</a></li>\n<li class="clickable"><a href="#summary">Summary</a></li>\n</ol>',
        },
        {
          id: 'inputs',
          type: 'list',
          readonly: false,
          label: 'Inputs',
          uid: '493d18fff3703876',
          content: [
            {
              id: 'question-one',
              type: 'list',
              readonly: false,
              label: 'Question One',
              uid: 'aa9a17714cf0b9cb',
              content: [
                {
                  id: 'system-constant',
                  type: 'html',
                  readonly: true,
                  label: '',
                  uid: 'adde3c4498bb38dc',
                  content:
                    '<ol>\n<li>Are you presently in good health, free from disease and injuries and still have full use of your limbs and eyes?</li>\n</ol>\n',
                },
                {
                  id: 'presently-in-good-health',
                  type: 'field',
                  elementType: 'radio',
                  readonly: false,
                  label: 'Presently In Good Health',
                  uid: '8ea1033695308a89',
                  content: '',
                  publishChange: true,
                  errors: ['*'],
                  options: ['Yes', 'No'],
                },
                {
                  id: 'presently-in-good-health-details',
                  type: 'field',
                  elementType: 'input',
                  readonly: false,
                  label: 'Details',
                  uid: 'a4d466281bb64299',
                  content: '',
                  publishChange: true,
                  inputType: 'text',
                },
              ],
            },
            {
              id: 'question-two',
              type: 'list',
              readonly: false,
              label: 'Question Two',
              uid: 'd13cb998af2237ed',
              content: [
                {
                  id: 'system-constant',
                  type: 'html',
                  readonly: true,
                  label: '',
                  uid: '45699498615ba20e',
                  content:
                    '<ol start="2">\n<li>Does your occupation, hobbies or pastimes involve you in any activity which may expose you to higher than average chance of premature death or becoming disabled?</li>\n</ol>\n',
                },
                {
                  id: 'risky-occupation-hobbies',
                  type: 'field',
                  elementType: 'radio',
                  readonly: false,
                  label: 'Risky Occupation Hobbies',
                  uid: '8cf7823cd5b86c1a',
                  content: '',
                  publishChange: true,
                  errors: ['*'],
                  options: ['Yes', 'No'],
                },
                {
                  id: 'risky-occupation-hobbies-details',
                  type: 'field',
                  elementType: 'input',
                  readonly: false,
                  label: 'Details',
                  uid: 'ca51bbe254570d5e',
                  content: '',
                  publishChange: true,
                  inputType: 'text',
                },
              ],
            },
            {
              id: 'question-three',
              type: 'list',
              readonly: false,
              label: 'Question Three',
              uid: '75425147e710ad12',
              content: [
                {
                  id: 'system-constant',
                  type: 'html',
                  readonly: true,
                  label: '',
                  uid: 'ddfd6be74618ad99',
                  content:
                    '<ol start="3">\n<li>In the past five years have you been examined by a doctor or been in hospital for treatment or examination or have you in the past or are you currently taking any drugs or prescripted medicine?</li>\n</ol>\n',
                },
                {
                  id: 'taking-prescriptions',
                  type: 'field',
                  elementType: 'radio',
                  readonly: false,
                  label: 'Taking Prescriptions',
                  uid: '9a0d0a79eda1ff85',
                  content: '',
                  publishChange: true,
                  errors: ['*', '*', '*', '*'],
                  options: ['Yes', 'No'],
                },
                {
                  id: 'taking-prescriptions-details',
                  type: 'field',
                  elementType: 'input',
                  readonly: false,
                  label: 'Details',
                  uid: '98f5180620620f31',
                  content: '',
                  publishChange: true,
                  inputType: 'text',
                },
              ],
            },
            {
              id: 'question-four',
              type: 'list',
              readonly: false,
              label: 'Question Four',
              uid: 'fd06ed61def1433a',
              content: [
                {
                  id: 'system-constant',
                  type: 'html',
                  readonly: true,
                  label: '',
                  uid: 'aea70675ab15680c',
                  content:
                    '<ol start="4">\n<li>Have you ever been tested for or receive medical advice, counselling or treatment in connection with any sexually transmitted disease? e.g. Hepatitis B, Gonorrhea, or Syphilis?</li>\n</ol>\n',
                },
                {
                  id: 'sexually-transmitted-diseases',
                  type: 'field',
                  elementType: 'radio',
                  readonly: false,
                  label: 'Sexually Transmitted Diseases',
                  uid: '808312aef133eb1d',
                  content: '',
                  publishChange: true,
                  errors: ['*', '*', '*', '*', '*'],
                  options: ['Yes', 'No'],
                },
                {
                  id: 'sexually-transmitted-diseases-details',
                  type: 'field',
                  elementType: 'input',
                  readonly: false,
                  label: 'Details',
                  uid: '07658ef261344281',
                  content: '',
                  publishChange: true,
                  inputType: 'text',
                },
              ],
            },
            {
              id: 'question-five',
              type: 'list',
              readonly: false,
              label: 'Question Five',
              uid: '5b62eed01f940ad2',
              content: [
                {
                  id: 'system-constant',
                  type: 'html',
                  readonly: true,
                  label: '',
                  uid: '6009fbcbf17490bc',
                  content:
                    '<ol start="5">\n<li>Has any Life Assurer ever refused an application on your life or changed an increased premoum for health or occupational reasons?</li>\n</ol>\n',
                },
                {
                  id: 'refused-application-or-increased-premium',
                  type: 'field',
                  elementType: 'radio',
                  readonly: false,
                  label: 'Refused Application Or Increased Premium',
                  uid: '3785ed19997787ea',
                  content: '',
                  publishChange: true,
                  errors: ['*', '*', '*', '*', '*', '*'],
                  options: ['Yes', 'No'],
                },
                {
                  id: 'refused-application-or-increased-premium-details',
                  type: 'field',
                  elementType: 'input',
                  readonly: false,
                  label: 'Details',
                  uid: '8373d9adfa85b6e0',
                  content: '',
                  publishChange: true,
                  inputType: 'text',
                },
              ],
            },
            {
              id: 'question-six',
              type: 'list',
              readonly: false,
              label: 'Question Six',
              uid: 'b54ea226eeee4b94',
              content: [
                {
                  id: 'system-constant',
                  type: 'html',
                  readonly: true,
                  label: '',
                  uid: 'c8bbb2b678529c5a',
                  content:
                    '<ol start="6">\n<li>Your measurements</li>\n</ol>\n',
                },
                {
                  id: 'system-constant',
                  type: 'html',
                  readonly: true,
                  label: '',
                  uid: '44b0dd57a23e359c',
                  content: '<p>Height (without shoes) in centimeters?</p>\n',
                },
                {
                  id: 'height-in-cm',
                  type: 'real',
                  elementType: 'input',
                  readonly: false,
                  label: 'Height In Cm',
                  uid: '4613694e7241953c',
                  content: '',
                  publishChange: true,
                  errors: ['*'],
                  inputType: 'number',
                },
                {
                  id: 'system-constant',
                  type: 'html',
                  readonly: true,
                  label: '',
                  uid: 'ec701c6b52819350',
                  content: '<p>Weight (in normal clothes) in kilograms?</p>\n',
                },
                {
                  id: 'weight-in-kg',
                  type: 'real',
                  elementType: 'input',
                  readonly: false,
                  label: 'Weight In Kg',
                  uid: '53f695362ea3a3a6',
                  content: '',
                  publishChange: true,
                  errors: ['*'],
                  inputType: 'number',
                },
                {
                  id: 'system-constant',
                  type: 'html',
                  readonly: true,
                  label: '',
                  uid: 'eda20db8207971dc',
                  content:
                    '<p>Has your weight changed by more than 5 kilograms during the past year?</p>\n',
                },
                {
                  id: 'weight-change',
                  type: 'field',
                  elementType: 'radio',
                  readonly: false,
                  label: 'Weight Change',
                  uid: 'd40adfaade1d2282',
                  content: '',
                  publishChange: true,
                  errors: ['*'],
                  options: ['Yes', 'No'],
                },
              ],
            },
            {
              id: 'question-seven',
              type: 'list',
              readonly: false,
              label: 'Question Seven',
              uid: '7ce9d280b5c6428a',
              content: [
                {
                  id: 'system-constant',
                  type: 'html',
                  readonly: true,
                  label: '',
                  uid: '28622f2e48de6dd3',
                  content: '<ol start="7">\n<li>SMOKING</li>\n</ol>\n',
                },
                {
                  id: 'system-constant',
                  type: 'html',
                  readonly: true,
                  label: '',
                  uid: '6f7de962f4cabb43',
                  content:
                    '<p>(NB: for rating purposes, a non-smoker is a person who has not smoked tobacco in any form for the past two years, has no intention of smoking</p>\n',
                },
                {
                  id: 'system-constant',
                  type: 'html',
                  readonly: true,
                  label: '',
                  uid: 'afb1e55de79cf6ce',
                  content:
                    '<p>in the future  and accepts that Enterprise Life Assurance Limited may require a urine test to check for nicotine traces)</p>\n',
                },
                {
                  id: 'system-constant',
                  type: 'html',
                  readonly: true,
                  label: '',
                  uid: 'ebc823e83eb9a1e8',
                  content: '<p>Current status:</p>\n',
                },
                {
                  id: 'smoker-options-radio-group',
                  type: 'list',
                  readonly: false,
                  label: 'Smoker Options Radio Group',
                  uid: 'ddad76e0a4dd2bde',
                  content: [
                    {
                      id: 'smoker',
                      type: 'field',
                      elementType: 'checkbox',
                      readonly: false,
                      label: 'Smoker',
                      uid: 'c2a298b63e9167ba',
                      content: '',
                      publishChange: true,
                      tooltip: 'Smoker',
                      options: ['Y', 'N'],
                    },
                    {
                      id: 'non-smoker',
                      type: 'field',
                      elementType: 'checkbox',
                      readonly: false,
                      label: 'Non Smoker',
                      uid: '99233cceebaa6e61',
                      content: '',
                      publishChange: true,
                      tooltip: 'Non Smoker',
                      options: ['Y', 'N'],
                    },
                    {
                      id: 'never-smoked',
                      type: 'field',
                      elementType: 'checkbox',
                      readonly: false,
                      label: 'Never Smoked',
                      uid: '323ec04a8314f4b7',
                      content: '',
                      publishChange: true,
                      tooltip: 'Never Smoked',
                      options: ['Y', 'N'],
                    },
                  ],
                  markers: [],
                  errors: ['*'],
                },
                {
                  id: 'years-smoking',
                  type: 'field',
                  elementType: 'input',
                  readonly: false,
                  label: 'Years Smoking',
                  uid: '0b916e1751d7889e',
                  content: '',
                  publishChange: true,
                  errors: ['*'],
                  inputType: 'text',
                },
                {
                  id: 'years-not-smoking',
                  type: 'field',
                  elementType: 'input',
                  readonly: false,
                  label: 'Years Not Smoking',
                  uid: '0b916e1751d7889f',
                  content: '',
                  publishChange: true,
                  errors: ['*'],
                  inputType: 'text',
                },
                {
                  id: 'smoking-details',
                  type: 'list',
                  readonly: false,
                  label: 'Smoking Details',
                  uid: '71763b9aeae14f09',
                  content: [
                    {
                      id: 'system-constant',
                      type: 'html',
                      readonly: true,
                      label: '',
                      uid: 'fd62ae9ecddf103e',
                      content: '<p>Average daily habit</p>\n',
                    },
                    {
                      id: 'cigarettes-per-day',
                      type: 'real',
                      elementType: 'input',
                      readonly: false,
                      label: 'Cigarettes Per Day',
                      uid: '2fa0ce8f9dd32383',
                      content: '',
                      publishChange: true,
                      errors: ['*'],
                      inputType: 'number',
                    },
                    {
                      id: 'cigars-per-day',
                      type: 'real',
                      elementType: 'input',
                      readonly: false,
                      label: 'Cigars Per Day',
                      uid: '149790ca67d0b1ee',
                      content: '',
                      publishChange: true,
                      errors: ['*'],
                      inputType: 'number',
                    },
                    {
                      id: 'pipe',
                      type: 'real',
                      elementType: 'input',
                      readonly: false,
                      label: 'Pipe',
                      uid: '6f74c7cb81c83ffc',
                      content: '',
                      publishChange: true,
                      errors: ['*'],
                      inputType: 'number',
                    },
                    {
                      id: 'medical-advice-stop-smoking',
                      type: 'field',
                      elementType: 'radio',
                      readonly: false,
                      label:
                        'Have you ever stopped smoking on specific medical advice?',
                      uid: 'bff7a54f8bb3d39b',
                      content: '',
                      publishChange: true,
                      options: ['Yes', 'No'],
                    },
                    {
                      id: 'medical-advice-stop-smoking-details',
                      type: 'field',
                      elementType: 'input',
                      readonly: false,
                      label: 'Details',
                      uid: 'f43b3fa26a7eb7f7',
                      content: '',
                      publishChange: true,
                      inputType: 'text',
                    },
                  ],
                },
              ],
            },
            {
              id: 'question-eight',
              type: 'list',
              readonly: false,
              label: 'Question Eight',
              uid: '67ea67f40fa845e3',
              content: [
                {
                  id: 'system-constant',
                  type: 'html',
                  readonly: true,
                  label: '',
                  uid: '1e386e3af5217d50',
                  content:
                    '<ol start="8">\n<li>ALCOHOL-CONSUMPTION</li>\n</ol>\n',
                },
                {
                  id: 'system-constant',
                  type: 'html',
                  readonly: true,
                  label: '',
                  uid: '9e7ab49641c31792',
                  content:
                    '<p>8.1 Have you ever received medical advice to reduce/discontinue liquor consumption?</p>\n',
                },
                {
                  id: 'medical-advice-reduce-drinking',
                  type: 'field',
                  elementType: 'radio',
                  readonly: false,
                  label: 'Medical Advice Reduce Drinking',
                  uid: '0420dce6895212c8',
                  content: '',
                  publishChange: true,
                  options: ['Yes', 'No'],
                },
                {
                  id: 'medical-advice-reduce-drinking-details',
                  type: 'field',
                  elementType: 'input',
                  readonly: false,
                  label: 'Details',
                  uid: '4ff4572c0a8c9eed',
                  content: '',
                  publishChange: true,
                  inputType: 'text',
                },
                {
                  id: 'system-constant',
                  type: 'html',
                  readonly: true,
                  label: '',
                  uid: 'ada438fe2bb1e1b3',
                  content:
                    '<p>8.2 Do you on average consume more than three alcoholic drinks per day?</p>\n',
                },
                {
                  id: 'system-constant',
                  type: 'html',
                  readonly: true,
                  label: '',
                  uid: '8fe47415b2acaee0',
                  content:
                    '<p>1 drink = 1 tot of spirit or 1 pint of beer or 1 glass of wine</p>\n',
                },
                {
                  id: 'excessive-drinking',
                  type: 'field',
                  elementType: 'radio',
                  readonly: false,
                  label: 'Excessive Drinking',
                  uid: '090499cf875ac3e3',
                  content: '',
                  publishChange: true,
                  options: ['Yes', 'No'],
                },
                {
                  id: 'excessive-drinking-details',
                  type: 'field',
                  elementType: 'input',
                  readonly: false,
                  label: 'Details',
                  uid: '4ff4572c0a8c9eed',
                  content: '',
                  publishChange: true,
                  inputType: 'text',
                },
                {
                  id: 'system-constant',
                  type: 'html',
                  readonly: true,
                  label: '',
                  uid: '8352046d5cea30e5',
                  content: '<p>8.3 Did you ever drink more in the past?</p>\n',
                },
                {
                  id: 'excessive-drinking-past',
                  type: 'field',
                  elementType: 'radio',
                  readonly: false,
                  label: 'Excessive Drinking Past',
                  uid: 'e15b59bd70a8102b',
                  content: '',
                  publishChange: true,
                  options: ['Yes', 'No'],
                },
                {
                  id: 'excessive-drinking-past-details',
                  type: 'field',
                  elementType: 'input',
                  readonly: false,
                  label: 'Details',
                  uid: '4ff4572c0a8c9eed',
                  content: '',
                  publishChange: true,
                  inputType: 'text',
                },
              ],
            },
          ],
        },
        {
          id: 'quotation-panel',
          type: 'list',
          readonly: true,
          label: 'Quotation Panel',
          uid: 'f92bebf5c8e3799e',
          content: [
            {
              id: 'quotation-information',
              type: 'list',
              readonly: true,
              label: 'Quotation Information',
              uid: '874801c29a27d8e3',
              content: [
                {
                  id: 'system-constant',
                  type: 'html',
                  readonly: true,
                  label: '',
                  uid: '9a9da8b55542d233',
                  content: '<p>Educare Plan <strong>Plus</strong></p>\n',
                },
                {
                  id: 'benefit-rate',
                  type: 'amount',
                  elementType: 'input',
                  readonly: true,
                  label: 'Benefit Rate',
                  uid: '234f9a4cc9b1210c',
                  content: '0.00',
                  inputType: 'number',
                },
                {
                  id: 'premium',
                  type: 'amount',
                  elementType: 'input',
                  readonly: true,
                  label: 'Premium',
                  uid: 'd3b718fa91ff280e',
                  content: '0.00',
                  inputType: 'number',
                },
              ],
            },
          ],
        },
      ],
    },
    title: 'Public Access \n Welcome to Elac Gambia',
    context: 'Educare Enhanced Medical Underwriting',
    uri: 'educare-plus/medical-underwriting',
    class: 'educare-enhanced-medical-underwriting',
  },
  //add health descriptions?
  'health-descriptions': {
    text: [],
    buttons: [
      { label: 'Next', id: 'next', uid: '26ca340069ec84e5', control: true },
      { label: 'Back', id: 'back', uid: '1303e2b2da021d46', control: true },
      { label: 'Cancel', id: 'cancel', uid: 'e25c3188a733209e', control: true },
    ],
    item: {
      id: 'health-descriptions',
      type: 'list',
      readonly: false,
      label: 'Health Descriptions',
      uid: 'fed88f569f729c85',
      content: [],
    },
    title: 'Public Access \n Welcome to Elac Gambia',
    context: 'Educare Enhanced Health Details',
    uri: 'educare-plus/health-details',
    class: 'educare-enhanced-health-details',
  },
  'payment-details': {
    text: [],
    buttons: [
      {
        label: 'Next',
        id: 'next',
        uid: '26ca340069ec84e5',
        control: true,
      },
      {
        label: 'Back',
        id: 'back',
        uid: '1303e2b2da021d46',
        control: true,
      },
      {
        label: 'Cancel',
        id: 'cancel',
        uid: 'e25c3188a733209e',
        control: true,
      },
    ],
    item: {
      id: 'payment-details',
      type: 'list',
      readonly: false,
      label: 'Payment Details',
      uid: '724c6e4d813e53cd',
      content: [
        {
          id: 'system-constant',
          type: 'html',
          readonly: true,
          label: '',
          uid: '53606e9c53fc5262',
          content: '<h2>Payment Details</h2>\n',
        },
        {
          id: 'system-constant',
          type: 'html',
          readonly: true,
          label: '',
          uid: '493a034ea85c537a',
          content:
            '<p>Please complete your preferred payment method and payment details below.</p>\n',
        },
        {
          id: 'output-html',
          type: 'html',
          readonly: true,
          label: 'Output',
          uid: '373c5662bc08cf41',
          content:
            "<ol class='progress-indicator'>\n<li class='done clickable'><a href='#cover'>Cover</a></li>\n<li class='done clickable'><a href='#additional'>Additional</a></li>\n<li class='done clickable'><a href='#details'>Details</a></li>\n<li class='done clickable'><a href='#underwriting'>Underwriting</a></li>\n<li class='done clickable'><a href='#payment'>Payment</a></li>\n<li class='clickable'><a href='#uploads'>Uploads</a></li>\n<li class='clickable'><a href='#summary'>Summary</a></li>\n</ol>",
        },
        {
          id: 'payment-method',
          type: 'list',
          readonly: false,
          label: 'Payment Method',
          uid: '10b84ec2f10c4bfb',
          content: [
            {
              id: 'system-constant',
              type: 'html',
              readonly: true,
              label: '',
              uid: 'b3820d390c57590c',
              content: '<p>Choose a payment method</p>\n',
            },
            {
              id: 'payment-method-radio-group',
              type: 'list',
              readonly: false,
              label: 'Payment Method Radio Group',
              uid: 'e0c507cd9659cdce',
              content: [
                {
                  id: 'debit-order',
                  type: 'field',
                  elementType: 'checkbox',
                  readonly: false,
                  label: 'Debit Order',
                  uid: '66026bce0175bd85',
                  content: '',
                  publishChange: true,
                  errors: ['*'],
                  options: ['Y', 'N'],
                },
                {
                  id: 'stop-order',
                  type: 'field',
                  elementType: 'checkbox',
                  readonly: false,
                  label: 'Stop Order',
                  uid: 'addc4bc1f4c832eb',
                  content: '',
                  publishChange: true,
                  options: ['Y', 'N'],
                },
              ],
            },
          ],
        },
        {
          id: 'payment-commencement',
          type: 'list',
          readonly: false,
          label: 'Payment Commencement',
          uid: '47ad55dfb0d4b655',
          content: [
            {
              id: 'system-constant',
              type: 'html',
              readonly: true,
              label: '',
              uid: 'a50540585ca12c07',
              content:
                '<p>Choose the payment start date and your payment frequency</p>\n',
            },
            {
              id: 'payment-start-date',
              type: 'integer',
              elementType: 'select',
              readonly: false,
              label: 'Payment Start Date',
              uid: 'cd4537bfa3fb5f7d',
              content: '',
              publishChange: true,
              errors: ['*'],
              options: ['', '20230901', '20231001', '20231101'],
            },
            {
              id: 'payment-frequency',
              type: 'field',
              elementType: 'radio',
              readonly: false,
              label: 'Payment Frequency',
              uid: 'c66202347740c47c',
              content: '',
              publishChange: true,
              errors: ['*', '*'],
              options: ['Half Yearly', 'Monthly', 'Quarterly', 'Yearly'],
            },
            {
              id: 'payer-person',
              type: 'list',
              readonly: false,
              label: 'Payer Person',
              uid: 'ce35234c9c489acc',
              content: [
                {
                  id: 'system-constant',
                  type: 'html',
                  readonly: true,
                  label: '',
                  uid: 'ee7c49bb35030b57',
                  content: '<p>Who will be paying for this policy?</p>\n',
                },
                {
                  id: 'payer-person-radio-group',
                  type: 'list',
                  readonly: false,
                  label: 'Payer Person Radio Group',
                  uid: '2c1019491493ebab',
                  content: [
                    {
                      id: 'myself',
                      type: 'field',
                      elementType: 'checkbox',
                      readonly: false,
                      label: 'Myself',
                      uid: '4d7d4200a4fd7585',
                      content: '',
                      publishChange: true,
                      errors: ['*'],
                      options: ['Y', 'N'],
                    },
                    {
                      id: 'my-spouse',
                      type: 'field',
                      elementType: 'checkbox',
                      readonly: false,
                      label: 'My Spouse',
                      uid: '6198ae13e0925e88',
                      content: '',
                      publishChange: true,
                      options: ['Y', 'N'],
                    },
                  ],
                },
              ],
            },
          ],
        },
        {
          id: 'quotation-panel',
          type: 'list',
          readonly: true,
          label: 'Quotation Panel',
          uid: 'fc4890e2e85c8b62',
          content: [
            {
              id: 'quotation-information',
              type: 'list',
              readonly: true,
              label: 'Quotation Information',
              uid: '5dc5af91efaa954b',
              content: [
                {
                  id: 'system-constant',
                  type: 'html',
                  readonly: true,
                  label: '',
                  uid: '0d11aa5e08854f81',
                  content: '<p>Educare Plan <strong>Plus</strong></p>\n',
                },
                {
                  id: 'premium',
                  type: 'amount',
                  elementType: 'input',
                  readonly: true,
                  label: 'Premium',
                  uid: 'fb3a74d8b4c60af2',
                  content: '315.00',
                  inputType: 'number',
                },
              ],
            },
          ],
        },
      ],
    },
    title: 'Public Access \n Welcome to Elac Gambia',
    context: 'Educare Enhanced Payment Details',
    uri: 'educare-plus/quote',
    class: 'educare-enhanced-payment-details',
  },
  'debit-order-details': {
    text: [],
    buttons: [
      {
        label: 'Next',
        id: 'next',
        uid: '26ca340069ec84e5',
        control: true,
      },
      {
        label: 'Back',
        id: 'back',
        uid: '1303e2b2da021d46',
        control: true,
      },
      {
        label: 'Cancel',
        id: 'cancel',
        uid: 'e25c3188a733209e',
        control: true,
      },
    ],
    item: {
      id: 'payment-details',
      type: 'list',
      readonly: false,
      label: 'Payment Details',
      uid: '0fb85295eff0f9d3',
      content: [
        {
          id: 'system-constant',
          type: 'html',
          readonly: true,
          label: '',
          uid: '53606e9c53fc5262',
          content: '<h2>Payment Details</h2>\n',
        },
        {
          id: 'system-constant',
          type: 'html',
          readonly: true,
          label: '',
          uid: '493a034ea85c537a',
          content:
            '<p>Please complete your preferred payment method and payment details below.</p>\n',
        },
        {
          id: 'output-html',
          type: 'html',
          readonly: true,
          label: 'Output',
          uid: '373c5662bc08cf41',
          content:
            "<ol class='progress-indicator'>\n<li class='done clickable'><a href='#cover'>Cover</a></li>\n<li class='done clickable'><a href='#additional'>Additional</a></li>\n<li class='done clickable'><a href='#details'>Details</a></li>\n<li class='done clickable'><a href='#underwriting'>Underwriting</a></li>\n<li class='done clickable'><a href='#payment'>Payment</a></li>\n<li class='clickable'><a href='#uploads'>Uploads</a></li>\n<li class='clickable'><a href='#summary'>Summary</a></li>\n</ol>",
        },
        {
          id: 'debit-order-details',
          type: 'list',
          readonly: false,
          label: 'Debit Order Details',
          uid: 'd3c9a331e021ed0b',
          content: [
            {
              id: 'system-constant',
              type: 'html',
              readonly: true,
              label: '',
              uid: '82bc4d510e05f52c',
              content: '<p>Complete the following details</p>\n',
            },
            {
              id: 'new-account-details',
              type: 'list',
              readonly: false,
              label: 'New Account Details',
              uid: 'ab888ee5c456cdea',
              content: [
                {
                  id: 'account-holder-name',
                  type: 'field',
                  elementType: 'input',
                  readonly: false,
                  label: 'Account Holder Name',
                  uid: '039eb80e4a10de79',
                  content: '',
                  publishChange: true,
                  errors: ['*'],
                  inputType: 'text',
                },
                {
                  id: 'account-type',
                  type: 'field',
                  elementType: 'select',
                  readonly: false,
                  label: 'Account Type',
                  uid: 'fbdc797b84a0f751',
                  content: '',
                  publishChange: true,
                  errors: ['*'],
                  options: ['', 'Current', 'Savings'],
                },
                {
                  id: 'account-entity-name',
                  type: 'field',
                  elementType: 'select',
                  readonly: false,
                  label: 'Account Entity Name',
                  uid: '41ac2dfe1beb4c8d',
                  content: '',
                  publishChange: true,
                  errors: ['*'],
                  options: [
                    '',
                    'Access Bank Gambia',
                    'Arab Gambia Islamic Bank',
                    'Bloom Bank Africa',
                    'Bsic Gambia',
                    'Ecobank Gambia',
                    'Fbn bank',
                    'Gt Bank Gambia',
                    'Megabank Gambia Limited',
                    'Reliance Gambia',
                    'Standard Chartered Bank',
                    'Supersonicz',
                    'Trust Bank Gambia',
                    'Vista bank',
                    'Zenith Bank Gambia',
                  ],
                },
                {
                  id: 'account-branch-name',
                  type: 'field',
                  elementType: 'select',
                  readonly: false,
                  label: 'Account Branch Name',
                  uid: '503a71583f1182e2',
                  content: '',
                  publishChange: true,
                  errors: ['*'],
                  options: [
                    '',
                    'Banjul branch',
                    'Basse branch',
                    'Kairaba avenue branch',
                    'Serekunda branch',
                    'Latrikunda sabiji branch',
                    'Bundung branch',
                    'Bambo branch',
                    'Senegambia branch',
                    'Bakau market branch',
                    'Old jeshwang branch',
                    'Jimpex branch',
                    'Brikama branch',
                    'Brusubi branch',
                    'Farafenni branch',
                    'Banjul market branch',
                    'Westfield branch',
                    'Bakau branch',
                    'Bakoteh branch',
                    'Yundum branch',
                    'Soma branch',
                    'Barra branch',
                    'Bakau',
                    'Farafenni',
                    'Westfield',
                    'Kairaba Avenue Branch',
                    'Banjul',
                    'Kanifing branch',
                    'Brikama',
                    'Bakau newtown branch',
                    'Kanilai branch',
                    'Serekunda',
                    'Basse',
                    'Senegambia',
                    'Kairaba avenue',
                    'Fajara',
                    'BASSE',
                    'BRUSUBI',
                    'WASSU',
                    'BRIKAMA',
                    'BANSANG',
                    'SOMA',
                    'FARAFENNI',
                    'BARRA',
                    'BWAIM',
                    'TANJAI',
                    'SANYANG',
                    'OLD YUNDUM',
                    'SUKUTA',
                    'LAMIN',
                    'LATRIKUNDA',
                    'SEREKUNDA',
                    'KAIRABA',
                    'BANJUL',
                    'Head Office',
                    'Kairaba Branch',
                    'Banjul Branch',
                    'Tallinding Branch',
                    'Sayerr Jobe Branch',
                    'Brikama',
                    'Farafenni Outlet',
                    'Brikama-Ba Branch',
                    'Latrikunda Sabiji Branch',
                    'Farafenni Branch',
                    'Basse Branch',
                    'BARRA BRANCH',
                    'OLD YUNDUM BRANCH',
                    'Kairaba Avenue',
                    'Serrekunda Branch',
                    'Bambo',
                    'Jimpex Branch',
                    'Brikama Branch',
                    'Senegambia Branch',
                    'Westfield Branch',
                    'Bakau Branch',
                    'Kanifing Branch',
                  ],
                },
                {
                  id: 'account-number',
                  type: 'field',
                  elementType: 'input',
                  readonly: false,
                  label: 'Account Number',
                  uid: '495c881bcf57de23',
                  content: '',
                  publishChange: true,
                  errors: ['*'],
                  inputType: 'text',
                },
              ],
            },
          ],
        },
        {
          id: 'signature',
          type: 'list',
          readonly: false,
          label: 'Signature',
          uid: '1848a6126f8f1403',
          content: [
            {
              id: 'interactive-canvas-html',
              type: 'component',
              component: 'InteractiveCanvas',
              readonly: false,
              label: 'Interactive Canvas',
              uid: 'b25a984636d42a56',
              content:
                '<h2>Sign in the area below</h2>\n<p>By signing in the allocated space below, you indicate that you confirm that the information above is correct and that you accept the Terms and Conditions</p>\n<div class=`interactive-canvas` data-message=`Please sign in the area below`></div>',
            },
          ],
        },
        {
          id: 'quotation-panel',
          type: 'list',
          readonly: true,
          label: 'Quotation Panel',
          uid: 'fc4890e2e85c8b62',
          content: [
            {
              id: 'quotation-information',
              type: 'list',
              readonly: true,
              label: 'Quotation Information',
              uid: '5dc5af91efaa954b',
              content: [
                {
                  id: 'system-constant',
                  type: 'html',
                  readonly: true,
                  label: '',
                  uid: '0d11aa5e08854f81',
                  content: '<p>Educare Plan <strong>Plus</strong></p>\n',
                },
                {
                  id: 'premium',
                  type: 'amount',
                  elementType: 'input',
                  readonly: true,
                  label: 'Premium',
                  uid: 'fb3a74d8b4c60af2',
                  content: '315.00',
                  inputType: 'number',
                },
              ],
            },
          ],
        },
      ],
    },
    title: 'Public Access \n Welcome to Elac Gambia',
    context: 'Educare Enhanced Payment Details',
    uri: 'educare-plus/quote',
    class: 'educare-enhanced-payment-details',
  },
  'stop-order-details': {
    text: [],
    buttons: [
      {
        label: 'Next',
        id: 'next',
        uid: '26ca340069ec84e5',
        control: true,
      },
      {
        label: 'Back',
        id: 'back',
        uid: '1303e2b2da021d46',
        control: true,
      },
      {
        label: 'Cancel',
        id: 'cancel',
        uid: 'e25c3188a733209e',
        control: true,
      },
    ],
    item: {
      id: 'payment-details',
      type: 'list',
      readonly: false,
      label: 'Payment Details',
      uid: '2d63ef7d8cdd2154',
      content: [
        {
          id: 'system-constant',
          type: 'html',
          readonly: true,
          label: '',
          uid: '53606e9c53fc5262',
          content: '<h2>Payment Details</h2>\n',
        },
        {
          id: 'system-constant',
          type: 'html',
          readonly: true,
          label: '',
          uid: '493a034ea85c537a',
          content:
            '<p>Please complete your preferred payment method and payment details below.</p>\n',
        },
        {
          id: 'output-html',
          type: 'html',
          readonly: true,
          label: 'Output',
          uid: '373c5662bc08cf41',
          content:
            "<ol class='progress-indicator'>\n<li class='done clickable'><a href='#cover'>Cover</a></li>\n<li class='done clickable'><a href='#additional'>Additional</a></li>\n<li class='done clickable'><a href='#details'>Details</a></li>\n<li class='done clickable'><a href='#underwriting'>Underwriting</a></li>\n<li class='done clickable'><a href='#payment'>Payment</a></li>\n<li class='clickable'><a href='#uploads'>Uploads</a></li>\n<li class='clickable'><a href='#summary'>Summary</a></li>\n</ol>",
        },
        {
          id: 'stop-order-details',
          type: 'list',
          readonly: false,
          label: 'Stop Order Details',
          uid: '69481fbb0e38ec74',
          content: [
            {
              id: 'employer-organisation',
              type: 'field',
              elementType: 'select',
              readonly: false,
              label: 'Employer Organisation',
              uid: '3b65f438cba83e25',
              content: '',
              publishChange: true,
              errors: ['*'],
              options: [
                '',
                'Abba',
                'Abc Basic Cycle',
                'Abc Learning Centre',
                'Accountant Generals Department',
                'Africell',
                'Afrimed Clinical Service',
                'Albaraca Nursery',
                'Alh Mamour Secka Memoria ',
                'Alieu Badara Senior Sec Sch',
                'Amaana',
                'Amitage Senior Secondary School',
                'Amra Total',
                'Anne Marie Jahouhey Academy Basic',
                'Anne Marie Rivier School',
                'Appletree International School',
                'Aprc Hospital',
                'Archdeacon George Memorial School',
                'Atkinson Ashfield Nursery School',
                'Atlantic Cleaning Services',
                'Babylon Basic Cycle',
                'Bakoteh Fishing Market and Ice Plant',
                'Banjul Breweries Ltd',
                'Banjul City Council',
                'Bansang Hospital',
                'Bansang Senior Secondary School',
                'Basse Area Council',
                'Bb Electrical and Construction',
                'Bendavia Travel Agency',
                'Bijilo Medical Centre',
                'Bottrop Senior Sec',
                'Breckwoldt',
                'Brikama Area Council',
                'Brikama Islamic Institute',
                'Brikamaba Upper and Senior Basic Sec Sch',
                'Bwiam Hospital',
                'Catholic Mission',
                'Chigambas Field Office',
                'Child Fund',
                'Cis Nursery',
                'Cluny Nursery School',
                'Comium',
                'Creative Minds Schools',
                'Da Vinci Sss and Ubs',
                'Dabananni Electrical',
                'Daddy Jobe',
                'Department of Parks and Wildlife',
                'Dongoroba Health Centre',
                'Elac Bill Payment Account',
                'Elac Cash Payments',
                'Elac Gambia Staff',
                'Elton Oil Company',
                'Emendy and Sons ',
                'Emmanuel Mission Centre',
                'Essau Senior Secondary School',
                'Eurafricaine D Industries',
                'Fam Engineering',
                'Farafenni Hospital',
                'Farafenni Senior School',
                'Fig Bureau De Change',
                'Focus Energy Solutions',
                'Future in Hands the Gambia',
                'Galp',
                'Gam Solar',
                'Gambega Ltd',
                'Gambia Armed Forces',
                'Gambia Bridge',
                'Gambia Bureau of Statistics',
                'Gambia Chamer of Commerce',
                'Gambia Civil Aviation Authority',
                'Gambia Fire and Rescue Services',
                'Gambia Immigration',
                'Gambia International Airlines',
                'Gambia National Petroleum Company',
                'Gambia Pastoral Institute',
                'Gambia Police Force',
                'Gambia Ports Authority',
                'Gambia Printing and Publication Corp',
                'Gambia Prisons Department',
                'Gambia Radio and Televion',
                'Gambia Revenue Authority',
                'Gambia Tourism and Hopitality',
                'Gambia Tourism Authority',
                'Gamcel',
                'Gammol Nursery School',
                'Gamsen Contruction Co Ltd',
                'Gamtel',
                'Gamwater',
                'Gbos',
                'Girls Guide School',
                'Global Electrical Group',
                'Government Treasury',
                'Hands Together for Gambia',
                'Holy Rosery Nursery School',
                'Immediate Money Transfer',
                'Interdem Enterprise',
                'International Pelican Seafood Co',
                'Jah Gas',
                'Jammeh Foundation for Peace',
                'Jarju Kunda Enterprise',
                'Jersey Tech Sen Sec School',
                'Jofi Nursery School',
                'John Pickering Senior Sec Sch',
                'Julbrew Ltd',
                'Kaira Su Federation',
                'Kindergarten Wattenshied Nursery',
                'King Star General Service',
                'Kings Kid Academy',
                'Kmc',
                'Kotu Senior Secondary Sch',
                'Kotu Upper Basic Sch',
                'Lamin Health Centre',
                'Living Children Academy',
                'Luthran Lower Basic School',
                'Mahad Senior Secondary School',
                'Makasutu Culture Forest ',
                'Management Development Institue',
                'Marakissa Kindergarten',
                'Marie Ritas Nursery School',
                'Mary Adoulie Samba Associates',
                'Masoor Senior Secondary School',
                'Mbowen Clinic',
                'Medicare Clinic',
                'Methodist Academy',
                'Methodist Academy Brikama',
                'Methodist Longman Memorial School',
                'Methodist Mission',
                'Methodist Preparatory Lower and Upper Sch',
                'Methodist Special School for Learning',
                'Mingdaw Upper and Senior Sec Sch',
                'Ministry of Agriculture',
                'Ministry of Basic and Secondary Education',
                'Ministry of Communication',
                'Ministry of Defence',
                'Ministry of Energy',
                'Ministry of Finance',
                'Ministry of Fisheries and National Assembly',
                'Ministry of Foreign Affairs',
                'Ministry of Forestry',
                'Ministry of Health',
                'Ministry of Interior',
                'Ministry of Justice',
                'Ministry of Local Government and Land',
                'Ministry of Petroleum',
                'Ministry of Tourism and Culture',
                'Ministry of Trade and Industry',
                'Ministry of Works and Construction',
                'Ministry of Youth and Sports',
                'Minstry of Higher Education',
                'Model Senior School',
                'Mrc',
                'Muslim Senior School',
                'Nasir Senior Secondary School',
                'Natcom Unesco',
                'National Aids Secretariat',
                'National Assembly',
                'National Audit',
                'National Drug Enforcement Agency',
                'National Environmental Agency',
                'National Planning Commission',
                'National Records Services',
                'National Road Authority',
                'National Water and Electricity Company',
                'Ndows Comprehensive Junior Sch',
                'Ndows Group of Schools',
                'Nema Real Estate Enterprise',
                'New Century',
                'New Convenant School',
                'Novascotia Gambia Association',
                'Nyaba Kunda',
                'Ocean Bay Hotel',
                'Office of the President',
                'Paradise Fm',
                'Paradise Suites',
                'Penyem Daycare and Nursury School',
                'Picca Nursery School',
                'Presentation Girl Vocational Sch',
                'Presentation of Mary Nursery School',
                'Project Aid the Gambia',
                'Prospects School Project',
                'Public Service Commission',
                'Public Utility Regulatory',
                'R and R Services',
                'Reach Education Centre',
                'Redeemers Schoole',
                'Remis Health Centre',
                'Rex Nursery School',
                'Riders for Health',
                'Rivier Nursery School',
                'Roots Health and Fitness',
                'Rosatou Nursery School',
                'Rvth',
                'S and S Properties',
                'Safari Garden Hotel',
                'Safari Motors Gambia Ltd',
                'Salvation Nursery School',
                'Samasang Federaton',
                'Sanneh Mentoring Nursery',
                'Seedlings Lbs',
                'Serrekunda Hospital',
                'Sheikh Hamdan Senior Sch',
                'Sheikh Zayed Eye Care Clinic',
                'Sifoe Senior Secondary School',
                "Sir Dembo's Lower and Upper Basic School",
                'Social Security and Housing Finance',
                'Sos Children Village',
                'St Francis Senior Secondary Sch',
                "St George's Ub and Ss Boarding School",
                "St Joseph's Senior Secondary School",
                'St Peters Tech Jnr and Sss',
                'Stepping Stone Nursery School',
                'Sukuta Wannsee School',
                'Sulayman Junkung General Hospital',
                'Sunset Beach Hotel',
                'Supersonicz Finance',
                'Tahir Ahamaiyya Muslm High School',
                'Takaful Gambia Limited',
                'Take Care Nursery School',
                'Tange Social Service Nursery',
                'Tarud',
                'Tk Motors',
                'Total Petroleum',
                'Trust Quality Design',
                'Vision Real Estate',
                'Wacc',
                'West Africa Exams Council',
                'West Aftrican Senior Secondary Sch',
                'Womens Bureau',
                'Zoe Nursery School',
              ],
            },
            {
              id: 'employee-number',
              type: 'field',
              elementType: 'input',
              readonly: false,
              label: 'Employee Number',
              uid: 'de5d87a0cd14bafb',
              content: '',
              publishChange: true,
              errors: ['*'],
              inputType: 'text',
            },
          ],
        },
        {
          id: 'signature',
          type: 'list',
          readonly: false,
          label: 'Signature',
          uid: '1848a6126f8f1403',
          content: [
            {
              id: 'interactive-canvas-html',
              type: 'component',
              component: 'InteractiveCanvas',
              readonly: false,
              label: 'Interactive Canvas',
              uid: 'b25a984636d42a56',
              content:
                '<h2>Sign in the area below</h2>\n<p>By signing in the allocated space below, you indicate that you confirm that the information above is correct and that you accept the Terms and Conditions</p>\n<div class=`interactive-canvas` data-message=`Please sign in the area below`></div>',
            },
          ],
        },
        {
          id: 'quotation-panel',
          type: 'list',
          readonly: true,
          label: 'Quotation Panel',
          uid: 'fc4890e2e85c8b62',
          content: [
            {
              id: 'quotation-information',
              type: 'list',
              readonly: true,
              label: 'Quotation Information',
              uid: '5dc5af91efaa954b',
              content: [
                {
                  id: 'system-constant',
                  type: 'html',
                  readonly: true,
                  label: '',
                  uid: '0d11aa5e08854f81',
                  content: '<p>Educare Plan <strong>Plus</strong></p>\n',
                },
                {
                  id: 'premium',
                  type: 'amount',
                  elementType: 'input',
                  readonly: true,
                  label: 'Premium',
                  uid: 'fb3a74d8b4c60af2',
                  content: '315.00',
                  inputType: 'number',
                },
              ],
            },
          ],
        },
      ],
    },
    title: 'Public Access \n Welcome to Elac Gambia',
    context: 'Educare Enhanced Payment Details',
    uri: 'educare-plus/quote',
    class: 'educare-enhanced-payment-details',
  },
  'upload-documents': {
    text: [],
    buttons: [
      {
        label: 'Upload ID document',
        id: 'upload-id-document',
        uid: '69a53b6758f4ff80',
        control: false,
      },
      {
        label: 'Next',
        id: 'next',
        uid: '26ca340069ec84e5',
        control: true,
      },
      {
        label: 'Back',
        id: 'back',
        uid: '1303e2b2da021d46',
        control: true,
      },
      {
        label: 'Cancel',
        id: 'cancel',
        uid: 'e25c3188a733209e',
        control: true,
      },
    ],
    item: {
      id: 'upload-documents',
      type: 'list',
      readonly: true,
      label: 'Upload Documents',
      uid: '992f29e1f0c7d33f',
      content: [
        {
          id: 'system-constant',
          type: 'html',
          readonly: true,
          label: '',
          uid: '2dd71fd55a00cef9',
          content: '<h2>Upload Documents</h2>\n',
        },
        {
          id: 'system-constant',
          type: 'html',
          readonly: true,
          label: '',
          uid: 'f4179312612d1ce4',
          content:
            '<p>Please upload the necessary documentation or proceed to the next page if you would like to provide it later.</p>\n',
        },
        {
          id: 'output-html',
          type: 'html',
          readonly: true,
          label: 'Output',
          uid: 'e567849ef903ec0e',
          content:
            "<ol class='progress-indicator'>\n<li class='done clickable'><a href='#cover'>Cover</a></li>\n<li class='done clickable'><a href='#additional'>Additional</a></li>\n<li class='done clickable'><a href='#details'>Details</a></li>\n<li class='done clickable'><a href='#underwriting'>Underwriting</a></li>\n<li class='done clickable'><a href='#payment'>Payment</a></li>\n<li class='done clickable'><a href='#uploads'>Uploads</a></li>\n<li class='clickable'><a href='#summary'>Summary</a></li>\n</ol>",
        },
        {
          id: 'documents-list',
          type: 'list',
          readonly: true,
          label: 'Documents List',
          uid: '8a30a43b57bb332b',
          content: [
            {
              id: 'system-field-outcome',
              type: 'html',
              readonly: true,
              label: '',
              uid: '7721dd56ec3e43de',
              content:
                "<p><a href='#upload-id-document' class=''>ID Document <span>Re Upload</span></a></p>\n",
            },
          ],
        },
        {
          id: 'quotation-panel',
          type: 'list',
          readonly: true,
          label: 'Quotation Panel',
          uid: 'b8fc76d9c36370b5',
          content: [
            {
              id: 'quotation-information',
              type: 'list',
              readonly: true,
              label: 'Quotation Information',
              uid: '50cc289580d5b95b',
              content: [
                {
                  id: 'system-constant',
                  type: 'html',
                  readonly: true,
                  label: '',
                  uid: '7d09b4eea675cb26',
                  content: '<p>Educare Plan <strong>Plus</strong></p>\n',
                },
                {
                  id: 'premium',
                  type: 'amount',
                  elementType: 'input',
                  readonly: true,
                  label: 'Premium',
                  uid: 'e8d4b11a9bdd5075',
                  content: '315.00',
                  inputType: 'number',
                },
              ],
            },
          ],
        },
      ],
    },
    title: 'Public Access \n Welcome to Elac Gambia',
    context: 'Educare Enhanced Upload Documents',
    uri: 'educare-plus/upload-documents',
    class: 'educare-enhanced-upload-documents',
  },
  'upload-document-pages': {
    modal: true,
    buttons: [
      {
        control: false,
        id: 'add-page',
        label: 'Add Page',
        uid: '1a5450d98db152d6',
      },
      {
        control: true,
        id: 'abort',
        label: 'Abort',
        uid: 'e11279c7f15a77ea',
      },
      {
        control: true,
        id: 'continue',
        label: 'Continue',
        uid: '0d54090d869303f6',
      },
    ],
    item: {
      content: [
        {
          content:
            '<p>Please upload your document below. If it consists of multiple pages, please upload all of them together.</p>\n',
          id: 'system-field-outcome',
          label: '',
          readonly: true,
          type: 'html',
          uid: '8872705861783d2d',
        },
        {
          content: [],
          id: 'uploads',
          label: 'Uploads',
          readonly: false,
          type: 'table',
          uid: '2d999fbaaefb947c',
        },
        {
          content:
            '<p><a class="button add-page-button" href="#add-page">Add Page</a></p>\n',
          id: 'system-constant',
          label: '',
          readonly: true,
          type: 'html',
          uid: 'a47ce0e3f28e09c5',
        },
      ],
      id: 'upload-section',
      label: 'Upload Section',
      readonly: false,
      type: 'list',
      uid: '74b77d523e9f9b6d',
    },
    class: 'upload-layout',
    context: 'Upload Layout',
    template: '',
    text: [],
    title: 'Public Access \n Welcome to Elac Gambia',
    uri: 'upload-document/file/new',
  },
  'acceptance-screen': {
    text: [],
    buttons: [
      {
        label: 'Submit',
        id: 'submit',
        uid: 'ef8243ce592928b2',
        control: false,
      },
      {
        label: 'Back',
        id: 'back',
        uid: '1303e2b2da021d46',
        control: true,
      },
      {
        label: 'Cancel',
        id: 'cancel',
        uid: 'e25c3188a733209e',
        control: true,
      },
    ],
    item: {
      id: 'acceptance',
      type: 'list',
      readonly: false,
      label: 'Acceptance',
      uid: 'c2bdc0e7fb6fc558',
      content: [
        {
          id: 'system-constant',
          type: 'html',
          readonly: true,
          label: '',
          uid: '981d430846482fe8',
          content: '<h2>Summary</h2>\n',
        },
        {
          id: 'system-constant',
          type: 'html',
          readonly: true,
          label: '',
          uid: '8b470d282f194b34',
          content: '<p>Please review the information below</p>\n',
        },
        {
          id: 'output-html',
          type: 'html',
          readonly: true,
          label: 'Output',
          uid: '65dbe261db324f63',
          content:
            "<ol class='progress-indicator'>\n<li class='done clickable'><a href='#cover'>Cover</a></li>\n<li class='done clickable'><a href='#details'>Details</a></li>\n<li class='done clickable'><a href='#underwriting'>Underwriting</a></li>\n<li class='done clickable'><a href='#payment'>Payment</a></li>\n<li class='done clickable'><a href='#uploads'>Uploads</a></li>\n<li class='done clickable'><a href='#summary'>Summary</a></li>\n</ol>",
        },
        {
          id: 'summary',
          type: 'list',
          readonly: true,
          label: 'Summary',
          uid: '765783c30c5c14b9',
          content: [
            {
              id: 'contract-id',
              type: 'field',
              elementType: 'input',
              readonly: true,
              label: 'Application Reference Number',
              uid: '4bf66d747037dcad',
              content: '',
              inputType: 'text',
            },
            {
              id: 'life-assured-identification',
              type: 'list',
              readonly: true,
              label: 'Life Assured Identification',
              uid: '86d130679ef886de',
              content: [
                {
                  id: 'first-names',
                  type: 'field',
                  elementType: 'input',
                  readonly: true,
                  label: '',
                  uid: '19db8e2701c35020',
                  content: 'Karla',
                  inputType: 'text',
                },
                {
                  id: 'surname',
                  type: 'field',
                  elementType: 'input',
                  readonly: true,
                  label: 'Surname',
                  uid: '8e7cf5eb8db24643',
                  content: '',
                  inputType: 'text',
                },
                {
                  id: 'date-of-birth',
                  type: 'integer',
                  elementType: 'input',
                  readonly: true,
                  label: 'Date Of Birth',
                  uid: '4fbcb5f5033fa4a4',
                  content: '',
                  inputType: 'number',
                },
                {
                  id: 'title',
                  type: 'field',
                  elementType: 'input',
                  readonly: true,
                  label: 'Title',
                  uid: '739147d2c5f65eda',
                  content: '',
                  inputType: 'text',
                },
                {
                  id: 'nationality',
                  type: 'field',
                  elementType: 'input',
                  readonly: true,
                  label: 'Nationality',
                  uid: 'fd96e1398cc06178',
                  content: '',
                  inputType: 'text',
                },
                {
                  id: 'gender',
                  type: 'field',
                  elementType: 'input',
                  readonly: true,
                  label: 'Gender',
                  uid: '981762bbc94aec4a',
                  content: '',
                  inputType: 'text',
                },
              ],
            },
            {
              id: 'life-assured-contact-details',
              type: 'list',
              readonly: true,
              label: 'Life Assured Contact Details',
              uid: '5ad10733804665ee',
              content: [
                {
                  id: 'cell-phone',
                  type: 'field',
                  elementType: 'input',
                  readonly: true,
                  label: 'Cell Phone',
                  uid: 'd0ade59163a39f65',
                  content: '',
                  inputType: 'tel',
                },
                {
                  id: 'email',
                  type: 'field',
                  elementType: 'input',
                  readonly: true,
                  label: 'Email',
                  uid: '726be641eaf32460',
                  content: '',
                  inputType: 'email',
                },
              ],
            },
            {
              id: 'life-assured-postal-address',
              type: 'list',
              readonly: true,
              label: 'Life Assured Postal Address',
              uid: 'a6e2340a68dbda6d',
              content: [
                {
                  id: 'town',
                  type: 'field',
                  elementType: 'input',
                  readonly: true,
                  label: 'Town',
                  uid: '9d15d3076b0f0ef8',
                  content: '',
                  inputType: 'text',
                },
                {
                  id: 'region',
                  type: 'field',
                  elementType: 'input',
                  readonly: true,
                  label: 'Region',
                  uid: '1c04ebd452a73998',
                  content: '',
                  inputType: 'text',
                },
              ],
            },
            {
              id: 'trustee-identification',
              type: 'list',
              readonly: true,
              label: 'Trustee Identification',
              uid: '455d1b6e3f101e5e',
              content: [
                {
                  id: 'first-names',
                  type: 'field',
                  elementType: 'input',
                  readonly: true,
                  label: 'First Names',
                  uid: 'bb6466c39025a913',
                  content: '',
                  inputType: 'text',
                },
                {
                  id: 'surname',
                  type: 'field',
                  elementType: 'input',
                  readonly: true,
                  label: 'Surname',
                  uid: '22f796d389808ca9',
                  content: '',
                  inputType: 'text',
                },
                {
                  id: 'date-of-birth',
                  type: 'integer',
                  elementType: 'input',
                  readonly: true,
                  label: 'Date Of Birth',
                  uid: '890da04c904445a0',
                  content: '',
                  inputType: 'number',
                },
                {
                  id: 'title',
                  type: 'field',
                  elementType: 'input',
                  readonly: true,
                  label: 'Title',
                  uid: '487e9507ab0315cd',
                  content: '',
                  inputType: 'text',
                },
                {
                  id: 'gender',
                  type: 'field',
                  elementType: 'input',
                  readonly: true,
                  label: 'Gender',
                  uid: '1de751e4b60cdea1',
                  content: '',
                  inputType: 'text',
                },
                {
                  id: 'relationship',
                  type: 'field',
                  elementType: 'input',
                  readonly: true,
                  label: 'Relationship',
                  uid: '7d4b3960ef930bfd',
                  content: '',
                  inputType: 'text',
                },
              ],
            },
            {
              id: 'trustee-contact-details',
              type: 'list',
              readonly: true,
              label: 'Trustee Contact Details',
              uid: '2e1aa846f46b2da3',
              content: [
                {
                  id: 'cell-phone',
                  type: 'field',
                  elementType: 'input',
                  readonly: true,
                  label: 'Cell Phone',
                  uid: '7c6757c71ce099fb',
                  content: '',
                  inputType: 'tel',
                },
              ],
            },
            {
              id: 'beneficiary-details',
              type: 'table',
              readonly: true,
              label: 'Beneficiary Details',
              uid: 'bc4c7c3edcf3d4be',
              content: [
                [
                  {
                    id: 'beneficiary-role',
                    type: 'field',
                    elementType: 'input',
                    readonly: true,
                    label: 'Beneficiary Role',
                    uid: '90f74f80c6fb538d',
                    content: '',
                    inputType: 'text',
                  },
                  {
                    id: 'first-names',
                    type: 'field',
                    elementType: 'input',
                    readonly: true,
                    label: 'First Names',
                    uid: '9236874fca654a4c',
                    content: '',
                    inputType: 'text',
                  },
                  {
                    id: 'surname',
                    type: 'field',
                    elementType: 'input',
                    readonly: true,
                    label: 'Surname',
                    uid: '557d7c0195ffd15b',
                    content: '',
                    inputType: 'text',
                  },
                  {
                    id: 'benefit-split',
                    type: 'real',
                    elementType: 'input',
                    readonly: true,
                    label: 'Benefit Split',
                    uid: '402a66dbc11e0c60',
                    content: '',
                    inputType: 'number',
                  },
                  {
                    id: 'role',
                    type: 'field',
                    elementType: 'input',
                    readonly: true,
                    label: 'Role',
                    uid: '35a6afe04925eace',
                    content: '',
                    inputType: 'text',
                  },
                  {
                    id: 'gender',
                    type: 'field',
                    elementType: 'input',
                    readonly: true,
                    label: 'Gender',
                    uid: 'e1aedd1cc1dd7b7d',
                    content: '',
                    inputType: 'text',
                  },
                  {
                    id: 'date-of-birth',
                    type: 'integer',
                    elementType: 'input',
                    readonly: true,
                    label: 'Date Of Birth',
                    uid: '61c5408a99676d66',
                    content: '',
                    inputType: 'number',
                  },
                ],
              ],
            },
            {
              id: 'payment-details',
              type: 'list',
              readonly: true,
              label: 'Payment Details',
              uid: '9f4ebc3347190f87',
              content: [],
            },
            {
              id: 'payment-bank-details',
              type: 'list',
              readonly: true,
              label: 'Payment Bank Details',
              uid: 'a0c7e049abfb193c',
              content: [
                {
                  id: 'payment-start-date',
                  type: 'integer',
                  elementType: 'input',
                  readonly: true,
                  label: 'Payment Start Date',
                  uid: 'f1ae4c5e23726d7c',
                  content: '',
                  inputType: 'number',
                },
              ],
            },
          ],
        },
        {
          id: 'interactive-canvas-html',
          type: 'component',
          component: 'InteractiveCanvas',
          readonly: false,
          label: 'Interactive Canvas',
          uid: '1fcad0e41194c3d1',
          content:
            '<h2>Sign in the area below</h2>\n<p>By signing in the allocated space below, you indicate that you confirm that the information above is correct and that you accept the Terms and Conditions</p>\n<div class=`interactive-canvas` data-message=`Please sign in the area below`></div>',
        },
        {
          id: 'quotation-panel',
          type: 'list',
          readonly: true,
          label: 'Quotation Panel',
          uid: 'd584845a5bcf09bb',
          content: [
            {
              id: 'quotation-information',
              type: 'list',
              readonly: true,
              label: 'Quotation Information',
              uid: 'b384f2e08ce0f339',
              content: [
                {
                  id: 'system-constant',
                  type: 'html',
                  readonly: true,
                  label: '',
                  uid: 'b4e74b8bc33817fb',
                  content: '<p>Educare Plan <strong>Plus</strong></p>\n',
                },
                {
                  id: 'premium',
                  type: 'amount',
                  elementType: 'input',
                  readonly: true,
                  label: 'Premium',
                  uid: '48f061e288e60d8b',
                  content: '315.00',
                  inputType: 'number',
                },
              ],
            },
          ],
        },
      ],
    },
    title: 'Public Access \n Welcome to Elac Gambia',
    context: 'Educare Enhanced Acceptance Screen',
    uri: 'educare-plus/acceptance',
    template: 'educare-enhanced-acceptance-screen',
    class: 'educare-enhanced-acceptance-screen',
  },
}

export const beneficiaryItem = (b, counter) => [
  {
    id: 'beneficiary-role',
    type: 'field',
    elementType: 'input',
    readonly: true,
    label: 'Beneficiary Role',
    uid: `f35595dfe84ccc32-${counter}`,
    content: `Beneficiary-${counter + 1}`,
    inputType: 'text',
  },
  {
    id: 'first-names',
    type: 'field',
    elementType: 'input',
    readonly: true,
    label: 'First Names',
    uid: `7565f9e726f220e7-${counter}`,
    content: b['first-names'] || '',
    inputType: 'text',
  },
  {
    id: 'surname',
    type: 'field',
    elementType: 'input',
    readonly: true,
    label: 'Surname',
    uid: `58b5d76aa0eaee8e-${counter}`,
    content: b.surname || '',
    inputType: 'text',
  },
  {
    id: 'benefit-split',
    type: 'real',
    elementType: 'input',
    readonly: true,
    label: 'Benefit Split',
    uid: `eac9af9f270f00ac-${counter}`,
    content: b['benefit-split'] || '',
    inputType: 'number',
  },
  {
    id: 'role',
    type: 'field',
    elementType: 'input',
    readonly: true,
    label: 'Role',
    uid: `c050b22de0adeb79-${counter}`,
    content: b.role || '',
    inputType: 'text',
  },
  {
    id: 'gender',
    type: 'field',
    elementType: 'input',
    readonly: true,
    label: 'Gender',
    uid: `0031b7feaa76cf4e-${counter}`,
    content: b.gender || '',
    inputType: 'text',
  },
  {
    id: 'date-of-birth',
    type: 'integer',
    elementType: 'input',
    readonly: true,
    label: 'Date Of Birth',
    uid: `7c2b63eca3329b34-${counter}`,
    content: b['date-of-birth'] || '',
    inputType: 'number',
  },
]

export const benefitSplitItem = (b, counter) => ({
  id: `beneficiary-${counter + 1}`,
  type: 'list',
  readonly: false,
  label: `Beneficiary-${counter + 1}`,
  uid: `8dca6eb49ac65bcc-${counter}`,
  content: [
    {
      id: 'system-constant',
      type: 'html',
      readonly: true,
      label: '',
      uid: `7259b360e6ec1490-${counter}`,
      content: `<p>${b['first-names']}</p>\n`,
    },
    {
      id: `beneficiary-split-${counter + 1}`,
      type: 'field',
      elementType: 'input',
      readonly: false,
      label: 'Beneficiary Split',
      uid: `8644e07220d3617d-${counter}`,
      content: b['benefit-split'] || '',
      publishChange: true,
      inputType: 'text',
    },
  ],
})

export const healthDetailsPrefix = [
  {
    id: 'system-constant',
    type: 'html',
    readonly: true,
    label: '',
    uid: '25e8ba914ed925d9',
    content: '<h2 id="health-description">Health description</h2>\n',
  },
  {
    id: 'system-constant',
    type: 'html',
    readonly: true,
    label: '',
    uid: '4798836a7c99c3c0',
    content:
      '<p>If the answer is &quot;Yes&quot; to questions 2-6 on the previous page please provide details below.</p>\n',
  },
  {
    id: 'output-html',
    type: 'html',
    readonly: true,
    label: 'Output Html',
    uid: '065a26e9f05f3475',
    content:
      '<ol class="progress-indicator">\n<li class="done clickable"><a href="#cover">Cover</a></li>\n<li class="done clickable"><a href="#details">Details</a></li>\n<li class="done clickable"><a href="#underwriting">Underwriting</a></li>\n<li class="clickable"><a href="#payment">Payment</a></li>\n<li class="clickable"><a href="#uploads">Uploads</a></li>\n<li class="clickable"><a href="#summary">Summary</a></li>\n</ol>',
  },
]

function range(start, end) {
  return new Array(end - start + 1).fill(undefined).map((_, i) => i + start)
}

export const healthDetailsItem = (count) =>
  range(1, count).map((counter) => ({
    id: `health-concern-${counter}`,
    type: 'list',
    readonly: false,
    label: `Health Concern ${counter}`,
    uid: `39858c9af82649b5-${counter}`,
    content: [
      {
        id: `complaints-${counter}`,
        type: 'field',
        elementType: 'select',
        readonly: false,
        label: 'Complaints',
        uid: `8faa00483cc51798-${counter}`,
        content: '',
        publishChange: true,
        errors: ['*'],
        tooltip: 'Complaints',
        options: [
          '',
          'Alcohol Past Consumption',
          'Arthritis',
          'Cancer',
          'Chest Or Respiratory Disorder',
          'Declined Or Loaded',
          'Diabetes',
          'Good Health',
          'Hazardous Pursuits',
          'Heart Problems',
          'Hereditary Diseases',
          'High Blood Pressure',
          'Hiv Positive',
          'Kidney Problems',
          'Medical Examination',
          'Mental Illness',
          'Nervous System Problems',
          'Other Disorders',
          'Prescribed Medication',
          'Previous Regular Treatment',
          'Previous Surgery',
          'Respiratory Problems',
          'Sexually Transmitted Diseases',
          'Skeletal Problems',
          'Stable Weight',
          'Stomach Problems',
        ],
      },
      {
        id: `complaint-description-${counter}`,
        type: 'field',
        elementType: 'input',
        readonly: false,
        label: 'Complaint Description',
        uid: `26a740e28b18766f-${counter}`,
        content: '',
        publishChange: true,
        errors: ['*'],
        inputType: 'text',
      },
      {
        id: `medical-condition-date-${counter}`,
        type: 'component',
        elementType: 'input',
        readonly: false,
        label: 'Medical Condition Date',
        uid: `f5ec4cc32a4803e5-${counter}`,
        content: '',
        publishChange: true,
        errors: ['*'],
        inputType: 'date',
        component: 'DateSelector',
      },
    ],
  }))

export const quotationPanel = {
  id: 'quotation-panel',
  type: 'list',
  readonly: true,
  label: 'Quotation Panel',
  uid: 'f92bebf5c8e3799e',
  content: [
    {
      id: 'quotation-information',
      type: 'list',
      readonly: true,
      label: 'Quotation Information',
      uid: '874801c29a27d8e3',
      content: [
        {
          id: 'system-constant',
          type: 'html',
          readonly: true,
          label: '',
          uid: '9a9da8b55542d233',
          content: '<p>Educare Plan <strong>Plus</strong></p>\n',
        },
        {
          id: 'benefit-rate',
          type: 'amount',
          elementType: 'input',
          readonly: true,
          label: 'Benefit Rate',
          uid: '234f9a4cc9b1210c',
          content: '0.00',
          inputType: 'number',
        },
        {
          id: 'premium',
          type: 'amount',
          elementType: 'input',
          readonly: true,
          label: 'Premium',
          uid: 'd3b718fa91ff280e',
          content: '0.00',
          inputType: 'number',
        },
      ],
    },
  ],
}

export const acceptancePaymentDetails = (form) => {
  if (form['payment-method-selection']['debit-order'] === 'Y') {
    return {
      content: [
        {
          content: form['payment-bank-details']['account-entity-name'] || '',
          elementType: 'input',
          id: 'account-entity-name',
          inputType: 'text',
          label: 'Account Entity Name',
          readonly: true,
          type: 'field',
          uid: 'e47cf0c452614349',
        },
        {
          content: form['payment-bank-details']['account-holder-name'] || '',
          elementType: 'input',
          id: 'account-holder-name',
          inputType: 'text',
          label: 'Account Holder Name',
          readonly: true,
          type: 'field',
          uid: '3d49b733e15abc7c',
        },
        {
          content: form['payment-bank-details']['account-number'] || '',
          elementType: 'input',
          id: 'account-number',
          inputType: 'text',
          label: 'Account Number',
          readonly: true,
          type: 'field',
          uid: 'cf966f446acc41ae',
        },
        {
          content: form['payment-bank-details']['account-type'] || '',
          elementType: 'input',
          id: 'account-type',
          inputType: 'text',
          label: 'Account Type',
          readonly: true,
          type: 'field',
          uid: 'e8cfb99fa1289443',
        },
        {
          content: form['payment-bank-details']['account-branch-name'] || '',
          elementType: 'input',
          id: 'account-branch-name',
          inputType: 'text',
          label: 'Account Branch Name',
          readonly: true,
          type: 'field',
          uid: '30738e87a98b959c',
        },
      ],
      id: 'payment-bank-details',
      label: 'Payment Bank Details',
      readonly: true,
      type: 'list',
      uid: '80eb57ac744be154',
    }
  } else if (form['payment-method-selection']['stop-order'] === 'Y') {
    return {
      id: 'payer-employment-details',
      type: 'list',
      readonly: true,
      label: 'Payer Employment Details',
      uid: '9f4ebc3347190f87',
      content: [
        {
          id: 'employer-organisation',
          type: 'field',
          elementType: 'input',
          readonly: true,
          label: 'Employer Organisation',
          uid: '8d379c9e95d45b1c',
          content:
            form['payment-employment-details']['employer-organisation'] || '',
          inputType: 'text',
        },
        {
          id: 'employee-number',
          type: 'field',
          elementType: 'input',
          readonly: true,
          label: 'Employee Number',
          uid: '2aeba28f1d28c239',
          content: form['payment-employment-details']['employee-number'] || '',
          inputType: 'text',
        },
      ],
    }
  } else {
    return null
  }
}
