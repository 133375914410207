/*
 * Enterprise Life Gambia offline validations
 */
export const hasValue = (value) => !!(value && value !== '')

export const validAgentId = (agentId) => {
  return /^[a-zA-Z0-9]{11}$/.test('' + agentId)
}

export const validName = (value) =>
  hasValue(value) && /^[^0-9]+$/.test(value.toString())

export const validCellnumber = (value) =>
  hasValue(value) && /^(?:\+220[1-9]{1}[0-9]{6})$/.test(value.toString())

export const onlyDigits = (value) =>
  hasValue(value) && /^[0-9]+$/.test(value.toString())
